<script>
import { mapMutations, mapState } from 'vuex';
import * as parkingDetailsMutations from '@/store/parkingDetails/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchParkingDetails from '@/api/buildings/patchParkingDetails';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnown = {
  YNNK_YES: 'Yes',
  YNNK_NO: 'No',
  YNNK_NOT_KNOWN: 'Not Known',
};

export default {
  name: 'ParkingDetails',
  components: { SimpleQuestion },
  computed: {
    ...mapState({
      s_arrangementsDescription: (state) => state.building.parkingDetails.arrangementsDescription,
      s_controlledParkingZone: (state) => state.building.parkingDetails.controlledParkingZone,
      s_buildingId: (state) => state.buildingId,
    }),
    // See https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
    arrangementsDescription: {
      get() {
        return this.s_arrangementsDescription;
      },
      set(value) {
        this[
          `${parkingDetailsMutations.PARKING_DETAILS_PREFIX}${parkingDetailsMutations.PARKING_DETAILS_SET_ARRANGEMENTS_DESCRIPTION}`
        ](value);
      },
    },
    controlledParkingZone: {
      get() {
        return {
          answer: this.s_controlledParkingZone,
        };
      },
      set(value) {
        this[
          `${parkingDetailsMutations.PARKING_DETAILS_PREFIX}${parkingDetailsMutations.PARKING_DETAILS_SET_CONTROLLED_PARKING_ZONE}`
        ](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      yesOrNoOrNotKnown,
    };
  },
  methods: {
    ...mapMutations([
      `${parkingDetailsMutations.PARKING_DETAILS_PREFIX}${parkingDetailsMutations.PARKING_DETAILS_SET_ARRANGEMENTS_DESCRIPTION}`,
      `${parkingDetailsMutations.PARKING_DETAILS_PREFIX}${parkingDetailsMutations.PARKING_DETAILS_SET_CONTROLLED_PARKING_ZONE}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchParkingDetails(this.s_buildingId, this.s_arrangementsDescription, this.s_controlledParkingZone);

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Parking</h2>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <h5>
            <label for="arrangementsDescription" class="mandatory"
              >What are the parking arrangements at the property?</label
            >
          </h5>

          <validation-provider name="parking arrangements" rules="required" v-slot="validationContext">
            <b-form-textarea
              v-model="arrangementsDescription"
              id="arrangementsDescription"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="controlledParkingZoneLabelName"
            title="Is the property in a controlled parking zone or within a local authority parking scheme?"
            v-model="controlledParkingZone"
            validationFieldName="controlled parking zone"
          ></simple-question>
        </div>
        <!-- /question 2 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
