import axios from 'axios';

export default ({
  id,
  sellerLivesAtProperty,
  seventeenOrOverLivesAtProperty,
  occupierNamesDescription,
  occupiersTenants,
  vacantPossession,
  occupantsLeavingBeforeCompletion,
  agreeToSaleContract,
  agreeToSaleContractDocuments,
}) => {
  const preparedData = {
    seventeen_or_over_lives_at_property: seventeenOrOverLivesAtProperty,
  };

  preparedData.seller_lives_at_property = typeof sellerLivesAtProperty !== 'undefined' ? sellerLivesAtProperty : null;

  preparedData.occupier_names_description = seventeenOrOverLivesAtProperty ? occupierNamesDescription : '';
  preparedData.occupiers_tenants = seventeenOrOverLivesAtProperty ? occupiersTenants : null;
  preparedData.vacant_possession = seventeenOrOverLivesAtProperty ? vacantPossession : null;

  preparedData.occupants_leaving_before_completion =
    seventeenOrOverLivesAtProperty && vacantPossession ? occupantsLeavingBeforeCompletion : null;
  preparedData.agree_to_sale_contract = seventeenOrOverLivesAtProperty && vacantPossession ? agreeToSaleContract : null;

  preparedData.agree_to_sale_contract_documents =
    seventeenOrOverLivesAtProperty && vacantPossession && !agreeToSaleContract ? agreeToSaleContractDocuments : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    occupier: preparedData,
  });
};
