import axios from 'axios';

export default (
  id,
  disputesComplaints,
  disputesComplaintsDescription,
  potentialDispute,
  potentialDisputeDescription,
) => {
  const preparedData = {};

  preparedData.disputes_complaints = disputesComplaints;
  preparedData.disputes_complaints_description = disputesComplaints ? disputesComplaintsDescription : '';

  preparedData.potential_dispute = potentialDispute;
  preparedData.potential_dispute_description = potentialDispute ? potentialDisputeDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    dispute: preparedData,
  });
};
