import axios from 'axios';

export default (
  id,
  newHomeWarranty,
  newHomeWarrantyCopy,
  dampProofing,
  dampProofingCopy,
  timberTreatment,
  timberTreatmentCopy,
  windows,
  windowsCopy,
  electricalWork,
  electricalWorkCopy,
  roofing,
  roofingCopy,
  centralHeating,
  centralHeatingCopy,
  underpinning,
  underpinningCopy,
  other,
  otherCopy,
  otherDescription,
  claims,
  claimsDescription,
) => {
  const preparedData = {};

  preparedData.new_home_warranty = newHomeWarranty;
  preparedData.new_home_warranty_copy = newHomeWarranty ? newHomeWarrantyCopy : null;

  preparedData.damp_proofing = dampProofing;
  preparedData.damp_proofing_copy = dampProofing ? dampProofingCopy : null;

  preparedData.timber_treatment = timberTreatment;
  preparedData.timber_treatment_copy = timberTreatment ? timberTreatmentCopy : null;

  preparedData.windows = windows;
  preparedData.windows_copy = windows ? windowsCopy : null;

  preparedData.electrical_work = electricalWork;
  preparedData.electrical_work_copy = electricalWork ? electricalWorkCopy : null;

  preparedData.roofing = roofing;
  preparedData.roofing_copy = roofing ? roofingCopy : null;

  preparedData.central_heating = centralHeating;
  preparedData.central_heating_copy = centralHeating ? centralHeatingCopy : null;

  preparedData.underpinning = underpinning;
  preparedData.underpinning_copy = underpinning ? underpinningCopy : null;

  preparedData.other = other;
  preparedData.other_copy = other ? otherCopy : null;
  preparedData.other_description = other ? otherDescription : '';

  preparedData.claims = claims;
  preparedData.claims_description = claims ? claimsDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    guarantee: preparedData,
  });
};
