<script>
import { mapMutations, mapState } from 'vuex';
import * as occupiersMutations from '@/store/occupiers/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchOccupiers from '@/api/buildings/patchOccupiers';
import getPerson from '@/api/persons/getPerson';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import occupiersDocuments from '@/functions/supportingDocuments/occupiers';

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'Occupiers',
  components: { SimpleQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.buildingId,
      s_sellerLivesAtProperty: (state) => state.building.occupiers.sellerLivesAtProperty,
      s_seventeenOrOverLivesAtProperty: (state) => state.building.occupiers.seventeenOrOverLivesAtProperty,
      s_occupiersIds: (state) => state.sale.occupierIds,
      s_occupierNamesDescription: (state) => state.building.occupiers.occupierNamesDescription,
      s_occupiersTenants: (state) => state.building.occupiers.occupiersTenants,
      s_vacantPossession: (state) => state.building.occupiers.vacantPossession,
      s_occupantsLeavingBeforeCompletion: (state) => state.building.occupiers.occupantsLeavingBeforeCompletion,
      s_agreeToSaleContract: (state) => state.building.occupiers.agreeToSaleContract,
      s_agreeToSaleContractDocuments: (state) => state.building.occupiers.agreeToSaleContractDocuments,
    }),
    sellerLivesAtProperty: {
      get() {
        return {
          answer: this.s_sellerLivesAtProperty,
        };
      },
      set(value) {
        this[`${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_SELLER_LIVES_AT_PROPERTY}`](
          value.answer,
        );
      },
    },
    seventeenOrOverLivesAtProperty: {
      get() {
        return {
          answer: this.s_seventeenOrOverLivesAtProperty,
        };
      },
      set(value) {
        this[
          `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY}`
        ](value.answer);
      },
    },
    occupierNamesDescription: {
      get() {
        return this.s_occupierNamesDescription;
      },
      set(value) {
        this[`${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_OCCUPIER_NAMES_DESCRIPTION}`](
          value,
        );
      },
    },
    occupiersTenants: {
      get() {
        return {
          answer: this.s_occupiersTenants,
        };
      },
      set(value) {
        this[`${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_OCCUPIERS_TENANTS}`](
          value.answer,
        );
      },
    },
    vacantPossession: {
      get() {
        return {
          answer: this.s_vacantPossession,
        };
      },
      set(value) {
        this[`${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_VACANT_POSSESSION}`](
          value.answer,
        );
      },
    },
    occupantsLeavingBeforeCompletion: {
      get() {
        return {
          answer: this.s_occupantsLeavingBeforeCompletion,
        };
      },
      set(value) {
        this[
          `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_OCCUPANTS_LEAVING_BEFORE_COMPLETION}`
        ](value.answer);
      },
    },
    agreeToSaleContract: {
      get() {
        return {
          answer: this.s_agreeToSaleContract,
        };
      },
      set(value) {
        this[`${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT}`](
          value.answer,
        );
      },
    },
    agreeToSaleContractDocuments: {
      get() {
        return {
          answer: this.s_agreeToSaleContractDocuments,
        };
      },
      set(value) {
        this[
          `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT_DOCUMENTS}`
        ](value.answer);
      },
    },
    setOccupierNamesDescription() {
      const description = [];
      this.occupiers.forEach((item) => {
        const fullName = `${item.first_name} ${item.last_name}`;
        description.push(fullName.replace(/\s{2,}/g, ' ')); // Replace double spaces and push
      });
      return description.join(', ');
    },
  },
  data() {
    return {
      saving: false,
      enclosedOrToFollow, // This refers to the content of the top on the file
      yesOrNo,
      occupiers: [],
      occupiersDocuments,
    };
  },
  methods: {
    ...mapMutations([
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_SELLER_LIVES_AT_PROPERTY}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_OCCUPIER_NAMES_DESCRIPTION}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_OCCUPIERS_TENANTS}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_VACANT_POSSESSION}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_OCCUPANTS_LEAVING_BEFORE_COMPLETION}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT_DOCUMENTS}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchOccupiers({
        id: this.s_buildingId,
        sellerLivesAtProperty: this.s_sellerLivesAtProperty,
        seventeenOrOverLivesAtProperty: this.s_seventeenOrOverLivesAtProperty,
        occupierNamesDescription: this.occupierNamesDescription,
        occupiersTenants: this.s_occupiersTenants,
        vacantPossession: this.s_vacantPossession,
        occupantsLeavingBeforeCompletion: this.s_occupantsLeavingBeforeCompletion,
        agreeToSaleContract: this.s_agreeToSaleContract,
        agreeToSaleContractDocuments: this.s_agreeToSaleContractDocuments,
      });

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
    fetchOccupiers: function () {
      Promise.all(this.s_occupiersIds.map(async (id) => await getPerson(id))).then((result) =>
        result.forEach((item) => this.occupiers.push(item.data)),
      );
    },
  },
  mounted() {
    this.fetchOccupiers();
  },
  watch: {
    occupiers: function () {
      this.occupierNamesDescription = this.setOccupierNamesDescription;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Occupiers</h2>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            v-model="sellerLivesAtProperty"
            :dropdownOptions="yesOrNo"
            labelName="sellerLivesAtPropertyLabelName"
            title="Does the seller live at the property?"
            validationFieldName="seller lives at property"
          ></simple-question>
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <simple-question
            v-model="seventeenOrOverLivesAtProperty"
            :dropdownOptions="yesOrNo"
            :lockChange="true"
            labelName="seventeenOrOverLivesAtPropertyLabelName"
            title="Does anyone else, aged 17 or over, live at the property? (based on Client Questionnaire answers)"
            validationFieldName="seventeen or over lives at property"
          ></simple-question>
        </div>

        <div class="col-md-12" v-if="seventeenOrOverLivesAtProperty.answer">
          <b-list-group class="p-0 mt-1" :flush="true">
            <b-list-group-item variant="light">
              <div class="col-6 d-inline-block col-md-4">Name</div>
              <div class="col-6 d-inline-block col-md-8">Age</div>
            </b-list-group-item>
            <b-list-group-item v-if="occupiers === 0" variant="light">
              <div>No occupiers.</div>
            </b-list-group-item>
            <b-list-group-item v-for="(item, index) in occupiers" :key="index" class="py-2" variant="light">
              <div class="col-6 d-inline-block col-md-4">
                {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
              </div>
              <div class="col-6 d-inline-block col-md-8">
                {{ item.profile_occupier.age }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <!-- /question 2 end -->

        <!-- If No, skip question bellow and jump to next section -->
        <div v-if="seventeenOrOverLivesAtProperty.answer">
          <div class="border-top my-3"></div>

          <!-- Question 3 -->
          <div class="col-md-12">
            <simple-question
              v-model="occupiersTenants"
              :dropdownOptions="yesOrNo"
              labelName="occupiersTenantsLabelName"
              title="Are any of the occupiers (other than the sellers), aged 17 or over, tenants or lodgers?"
              validationFieldName="occupiers tenants"
            ></simple-question>
          </div>
          <!-- /question 3 end -->

          <div class="border-top my-3"></div>

          <!-- Question 4 -->
          <div class="col-md-12">
            <simple-question
              v-model="vacantPossession"
              :dropdownOptions="yesOrNo"
              labelName="vacantPossessionLabelName"
              title="Is the property being sold with vacant possession?"
              validationFieldName="vacant possession"
            ></simple-question>

            <!-- If Yes, show two additional questions-->
            <div v-if="vacantPossession.answer">
              <h5><label>If Yes, have all the occupiers aged 17 or over:</label></h5>

              <simple-question
                v-model="occupantsLeavingBeforeCompletion"
                :dropdownOptions="yesOrNo"
                labelName="occupantsLeavingBeforeCompletionLabelName"
                titleNumberOrLetter="(a)"
                title="agreed to leave prior to completion?"
                validationFieldName="occupants leaving before completion"
              ></simple-question>

              <simple-question
                v-model="agreeToSaleContract"
                :dropdownOptions="yesOrNo"
                titleNumberOrLetter="(b)"
                labelName="agreeToSaleContractLabelName"
                title="agreed to sign the sale contract? If No, please supply other evidence that the property
                                will be vacant on completion."
                validationFieldName="agree to sale contract"
              ></simple-question>

              <div v-if="!agreeToSaleContract.answer">
                <simple-question
                  v-model="agreeToSaleContractDocuments"
                  :dropdownOptions="enclosedOrToFollow"
                  labelName="agreeToSaleContractDocumentsLabelName"
                  title=""
                  validationFieldName="agree to sale contract documents"
                ></simple-question>
                <inline-file-uploader
                  v-if="agreeToSaleContractDocuments.answer === 'ETF_ENCLOSED'"
                  :requiredDocument="occupiersDocuments.agreeToSaleContractDocuments"
                />
              </div>
              <!-- /question 7 end -->
            </div>
            <!-- /If Yes, show two additional questions-->
          </div>
          <!-- /end question 4 -->
        </div>
        <!-- /Iif no, skip question bellow and jump to next section -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
