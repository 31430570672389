import axios from 'axios';

export default (
  id,
  flooding,
  floodingDescription,
  groundWater,
  sewerFlooding,
  surfaceWater,
  coastalFlooding,
  riverFlooding,
  other,
  otherDescription,
  floodRiskReport,
  floodRiskReportCopy,
  radonTest,
  radonTestReport,
  radonTestBelowRecommendedActionLevel,
  radonTestRemedialMeasures,
  epcCopy,
  installationsGreenDealFinanced,
  installationsGreenDealFinancedElectricityBill,
  installationsGreenDealFinancedDescription,
  propertyAffectedByJapaneseKnotweed,
  japaneseKnotweedManagementPlan,
  japaneseKnotweedManagementPlanCopy,
) => {
  const preparedData = {
    radon_test_remedial_measures: radonTestRemedialMeasures,
    epc_copy: epcCopy,
  };

  preparedData.flooding = flooding;
  preparedData.flooding_description = flooding ? floodingDescription : null;
  preparedData.ground_water = flooding ? groundWater : null;
  preparedData.sewer_flooding = flooding ? sewerFlooding : null;
  preparedData.surface_water = flooding ? surfaceWater : null;
  preparedData.coastal_flooding = flooding ? coastalFlooding : null;
  preparedData.river_flooding = flooding ? riverFlooding : null;
  preparedData.other = flooding ? other : null;
  preparedData.other_description = other ? otherDescription : '';

  preparedData.flood_risk_report = floodRiskReport;
  preparedData.flood_risk_report_copy = floodRiskReport ? floodRiskReportCopy : null;

  preparedData.radon_test = radonTest;
  preparedData.radon_test_report = radonTest ? radonTestReport : null;
  preparedData.radon_test_below_recommended_action_level = radonTest ? radonTestBelowRecommendedActionLevel : null;

  preparedData.installations_green_deal_financed = installationsGreenDealFinanced;
  preparedData.installations_green_deal_financed_electricity_bill = installationsGreenDealFinanced
    ? installationsGreenDealFinancedElectricityBill
    : null;
  preparedData.installations_green_deal_financed_description = installationsGreenDealFinanced
    ? installationsGreenDealFinancedDescription
    : '';

  preparedData.property_affected_by_japanese_knotweed = propertyAffectedByJapaneseKnotweed;
  preparedData.japanese_knotweed_management_plan =
    propertyAffectedByJapaneseKnotweed === 'YNNK_YES' ? japaneseKnotweedManagementPlan : null;
  preparedData.japanese_knotweed_management_plan_copy =
    propertyAffectedByJapaneseKnotweed === 'YNNK_YES' ? japaneseKnotweedManagementPlanCopy : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    environmental_matter: preparedData,
  });
};
