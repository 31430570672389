<script>
import reduce from 'lodash/reduce';

const boundaryOptions = {
  SELLER: 'Seller',
  NEIGHBOUR: 'Neighbour',
  SHARED: 'Shared',
  NOT_KNOWN: 'Not Known',
};

export default {
  name: 'BoundaryArea',
  props: {
    label: {
      type: String,
      required: true,
    },
    validationFieldName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      boundaryOptions,
      chosenAnswer: this.value,
    };
  },
  methods: {
    boundaryChanged() {
      this.$emit('input', this.chosenAnswer);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  computed: {
    dropdownComputedOptions: function () {
      return reduce(
        this.boundaryOptions,
        (accumulator, value, key) => {
          const option = {
            value: key,
            text: value,
          };
          accumulator.push(option);
          return accumulator;
        },
        [{ value: null, text: 'Please choose', disabled: true, selected: true }],
      );
    },
  },
};
</script>

<template>
  <div class="row justify-content-center text-center">
    <div class="col-sm-12 col-xl-8">
      <ValidationProvider :name="validationFieldName" rules="required" v-slot="validationContext">
        <div class="row">
          <div class="col-md-6 col-form-label">
            <label :for="`${label}area`" class="mandatory">{{ label }}</label>
          </div>
          <div class="form-group col-md-6">
            <b-form-select
              :options="dropdownComputedOptions"
              :state="getValidationState(validationContext)"
              aria-describedby="boundary-type-error"
              class="form-control bg-white"
              :id="`${label}area`"
              name="title"
              v-model="chosenAnswer"
              @change="boundaryChanged"
            ></b-form-select>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="select-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>
