import axios from 'axios';

export default (
  id,
  sameDayCompletion,
  sellerMovingDateRequirements,
  sellerMovingDateRequirementsDescription,
  salePriceExceedsMortgageCharges,
  buildingTidy,
  emptyLightFittingsReplaced,
  securityDetailsProvidedAtBuilding,
  fittingRemovalCare,
) => {
  const preparedData = {
    same_day_completion: sameDayCompletion,
    sale_price_exceeds_mortgage_charges: salePriceExceedsMortgageCharges,
    building_tidy: buildingTidy,
    empty_light_fittings_replaced: emptyLightFittingsReplaced,
    security_details_provided_at_building: securityDetailsProvidedAtBuilding,
    fitting_removal_care: fittingRemovalCare,
  };

  preparedData.seller_moving_date_requirements = sellerMovingDateRequirements;
  preparedData.seller_moving_date_requirements_description = sellerMovingDateRequirements
    ? sellerMovingDateRequirementsDescription
    : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    transaction: preparedData,
  });
};
