import axios from 'axios';

export default (
  id,
  electricity,
  electricityProviderName,
  electricityLocationOfMeter,
  gas,
  gasProviderName,
  gasLocationOfMeter,
  water,
  waterProviderName,
  waterLocationOfStopcock,
  waterLocationOfMeter,
  sewerage,
  sewerageProviderName,
  telephone,
  telephoneProviderName,
  cable,
  cableProviderName,
) => {
  const preparedData = {};

  preparedData.telephone = telephone;
  preparedData.telephone_provider_name = telephone ? telephoneProviderName : null;

  preparedData.sewerage = sewerage;
  preparedData.sewerage_provider_name = sewerage ? sewerageProviderName : null;

  preparedData.water = water;
  preparedData.water_provider_name = water ? waterProviderName : null;
  preparedData.water_location_of_stopcock = water ? waterLocationOfStopcock : null;
  preparedData.water_location_of_meter = water ? waterLocationOfMeter : null;

  preparedData.gas = gas;
  preparedData.gas_provider_name = gas ? gasProviderName : null;
  preparedData.gas_location_of_meter = gas ? gasLocationOfMeter : null;

  preparedData.electricity = electricity;
  preparedData.electricity_provider_name = electricity ? electricityProviderName : null;
  preparedData.electricity_location_of_meter = electricity ? electricityLocationOfMeter : null;

  preparedData.cable = cable;
  preparedData.cable_provider_name = cable ? cableProviderName : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    utility_connection: preparedData,
  });
};
