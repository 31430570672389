<script>
import { mapMutations, mapState } from 'vuex';
import * as rightsMutations from '@/store/rights/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchRights from '@/api/buildings/patchRights';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import rights from '@/functions/supportingDocuments/rights';

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnown = {
  YNNK_YES: 'Yes',
  YNNK_NO: 'No',
  YNNK_NOT_KNOWN: 'Not Known',
};

export default {
  components: { SimpleQuestion, OpenQuestion, InlineFileUploader },
  name: 'Rights',
  computed: {
    ...mapState({
      s_sharedServiceMaintenance: (state) => state.building.rights.sharedServiceMaintenance,
      s_sharedServiceMaintenanceDescription: (state) => state.building.rights.sharedServiceMaintenanceDescription,
      s_neighbouringRights: (state) => state.building.rights.neighbouringRights,
      s_neighbouringRightsDescription: (state) => state.building.rights.neighbouringRightsDescription,
      s_accessPrevention: (state) => state.building.rights.accessPrevention,
      s_accessPreventionDescription: (state) => state.building.rights.accessPreventionDescription,
      s_rightsOfLight: (state) => state.building.rights.rightsOfLight,
      s_adjoiningProperties: (state) => state.building.rights.adjoiningProperties,
      s_customaryRights: (state) => state.building.rights.customaryRights,
      s_minesAndMinerals: (state) => state.building.rights.minesAndMinerals,
      s_chancelRepairLiability: (state) => state.building.rights.chancelRepairLiability,
      s_resourceRights: (state) => state.building.rights.resourceRights,
      s_arrangementsDescription: (state) => state.building.rights.arrangementsDescription,
      s_otherArrangements: (state) => state.building.rights.otherArrangements,
      s_otherArrangementsDescription: (state) => state.building.rights.otherArrangementsDescription,
      s_pipesCrossNeighbours: (state) => state.building.rights.pipesCrossNeighbours,
      s_neighboursPipesCross: (state) => state.building.rights.neighboursPipesCross,
      s_pipesAgreement: (state) => state.building.rights.pipesAgreement,
      s_pipesAgreementDescription: (state) => state.building.rights.pipesAgreementDescription,
      s_pipesAgreementCopy: (state) => state.building.rights.pipesAgreementCopy,
      s_buildingId: (state) => state.buildingId,
    }),
    sharedServiceMaintenance: {
      get() {
        return {
          answer: this.s_sharedServiceMaintenance,
          description: this.s_sharedServiceMaintenanceDescription,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_SHARED_SERVICE_MAINTENANCE}`](value.answer);
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_SHARED_SERVICE_MAINTENANCE_DESCRIPTION}`](
          value.description,
        );
      },
    },
    neighbouringRights: {
      get() {
        return {
          answer: this.s_neighbouringRights,
          description: this.s_neighbouringRightsDescription,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_NEIGHBOURING_RIGHTS}`](value.answer);
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_NEIGHBOURING_RIGHTS_DESCRIPTION}`](
          value.description,
        );
      },
    },
    accessPrevention: {
      get() {
        return {
          answer: this.s_accessPrevention,
          description: this.s_accessPreventionDescription,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ACCESS_PREVENTION}`](value.answer);
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ACCESS_PREVENTION_DESCRIPTION}`](
          value.description,
        );
      },
    },
    rightsOfLight: {
      get() {
        return {
          answer: this.s_rightsOfLight,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_RIGHTS_OF_LIGHT}`](value.answer);
      },
    },
    adjoiningProperties: {
      get() {
        return {
          answer: this.s_adjoiningProperties,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ADJOINING_PROPERTIES}`](value.answer);
      },
    },
    customaryRights: {
      get() {
        return {
          answer: this.s_customaryRights,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_CUSTOMARY_RIGHTS}`](value.answer);
      },
    },
    minesAndMinerals: {
      get() {
        return {
          answer: this.s_minesAndMinerals,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_MINES_AND_MINERALS}`](value.answer);
      },
    },
    chancelRepairLiability: {
      get() {
        return {
          answer: this.s_chancelRepairLiability,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_CHANCEL_REPAIR_LIABILITY}`](value.answer);
      },
    },
    resourceRights: {
      get() {
        return {
          answer: this.s_resourceRights,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_RESOURCE_RIGHTS}`](value.answer);
      },
    },
    arrangementsDescription: {
      get() {
        return this.s_arrangementsDescription;
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ARRANGEMENTS_DESCRIPTION}`](value);
      },
    },
    otherArrangements: {
      get() {
        return {
          answer: this.s_otherArrangements,
          description: this.s_otherArrangementsDescription,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_OTHER_ARRANGEMENTS}`](value.answer);
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_OTHER_ARRANGEMENTS_DESCRIPTION}`](
          value.description,
        );
      },
    },
    pipesCrossNeighbours: {
      get() {
        return {
          answer: this.s_pipesCrossNeighbours,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_CROSS_NEIGHBOURS}`](value.answer);
      },
    },
    neighboursPipesCross: {
      get() {
        return {
          answer: this.s_neighboursPipesCross,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_NEIGHBOURS_PIPES_CROSS}`](value.answer);
      },
    },
    pipesAgreement: {
      get() {
        return {
          answer: this.s_pipesAgreement,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_AGREEMENT}`](value.answer);
      },
    },
    pipesAgreementDescription: {
      get() {
        return this.s_pipesAgreementDescription;
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_AGREEMENT_DESCRIPTION}`](value);
      },
    },
    pipesAgreementCopy: {
      get() {
        return {
          answer: this.s_pipesAgreementCopy,
        };
      },
      set(value) {
        this[`${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_AGREEMENT_COPY}`](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      yesOrNoOrNotKnown,
      enclosedOrToFollow,
      rights,
    };
  },
  methods: {
    ...mapMutations([
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_SHARED_SERVICE_MAINTENANCE}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_SHARED_SERVICE_MAINTENANCE_DESCRIPTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_NEIGHBOURING_RIGHTS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_NEIGHBOURING_RIGHTS_DESCRIPTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ACCESS_PREVENTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ACCESS_PREVENTION_DESCRIPTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_RIGHTS_OF_LIGHT}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ADJOINING_PROPERTIES}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_CUSTOMARY_RIGHTS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_MINES_AND_MINERALS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_CHANCEL_REPAIR_LIABILITY}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_RESOURCE_RIGHTS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_ARRANGEMENTS_DESCRIPTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_OTHER_ARRANGEMENTS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_OTHER_ARRANGEMENTS_DESCRIPTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_CROSS_NEIGHBOURS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_NEIGHBOURS_PIPES_CROSS}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_AGREEMENT}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_AGREEMENT_DESCRIPTION}`,
      `${rightsMutations.RIGHTS_PREFIX}${rightsMutations.RIGHTS_SET_PIPES_AGREEMENT_COPY}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchRights(
        this.s_buildingId,
        this.s_sharedServiceMaintenance,
        this.s_sharedServiceMaintenanceDescription,
        this.s_neighbouringRights,
        this.s_neighbouringRightsDescription,
        this.s_accessPrevention,
        this.s_accessPreventionDescription,
        this.s_rightsOfLight,
        this.s_adjoiningProperties,
        this.s_customaryRights,
        this.s_minesAndMinerals,
        this.s_chancelRepairLiability,
        this.s_resourceRights,
        this.s_arrangementsDescription,
        this.s_otherArrangements,
        this.s_otherArrangementsDescription,
        this.s_pipesCrossNeighbours,
        this.s_neighboursPipesCross,
        this.s_pipesAgreement,
        this.s_pipesAgreementCopy,
        this.s_pipesAgreementDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Rights and informal arrangements</h2>

      <div class="col-md-12">
        <div class="section-note">
          <p>
            <b>Note: </b>Rights and arrangements may relate to access or shared use. They may also include leases of
            less than seven years, rights to mines and minerals, manorial rights, chancel repair and similar matters. If
            you are uncertain about whether a right or arrangement is covered by this question, please ask your
            solicitor.
          </p>
        </div>
      </div>

      <div class="border-top my-3"></div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <open-question
            dropdownName="sharedServiceMaintenanceDropdownName"
            labelName="sharedServiceMaintenanceLabelName"
            detailsName="shared service maintenance details"
            textAreaName="sharedServiceMaintenanceTextAreaName"
            title="Does ownership of the property carry a responsibility to contribute towards the cost of any
                  jointly used services, such as maintenance of a private road, a shared driveway, a boundary or drain?"
            descriptionTitle="If Yes, please give details."
            v-model="sharedServiceMaintenance"
            validationFieldName="shared service maintenance"
          ></open-question>
        </div>
        <!-- question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <open-question
            dropdownName="neighbouringRightsDropdownName"
            labelName="neighbouringRightsLabelName"
            detailsName="neighbouring rights details"
            textAreaName="neighbouringRightsTextAreaName"
            title="Does the property benefit from any rights or arrangements over any neighbouring property
                  (this includes any rights of way)?"
            descriptionTitle="If Yes, please give details."
            v-model="neighbouringRights"
            validationFieldName="neighbouring rights"
          ></open-question>
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <open-question
            dropdownName="accessPreventionDropdownName"
            labelName="accessPreventionLabelName"
            detailsName="access prevention details"
            textAreaName="accessPreventionTextAreaName"
            title="Has anyone taken steps to prevent access to the property, or to complain about or demand payment
                  for access to the property?"
            descriptionTitle="If Yes, please give details."
            v-model="accessPrevention"
            validationFieldName="access prevention"
          ></open-question>
        </div>
        <!-- /question 3 end -->

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <h5>Does the seller know if any of the following rights benefit the property:</h5>

          <simple-question
            titleNumberOrLetter="(a)"
            :dropdownOptions="yesOrNo"
            labelName="rightsOfLightLabelName"
            title="Rights of light"
            v-model="rightsOfLight"
            validationFieldName="rights of light"
          ></simple-question>

          <simple-question
            titleNumberOrLetter="(b)"
            :dropdownOptions="yesOrNo"
            labelName="adjoiningPropertiesLabelName"
            title="Rights of support from adjoining properties"
            v-model="adjoiningProperties"
            validationFieldName="adjoining properties"
          ></simple-question>

          <simple-question
            titleNumberOrLetter="(c)"
            :dropdownOptions="yesOrNo"
            labelName="customaryRightsLabelName"
            title="Customary rights (eg rights deriving from local traditions)"
            v-model="customaryRights"
            validationFieldName="customary rights"
          ></simple-question>
        </div>
        <!--      question 4/end-->

        <!--        Question 5-->
        <div class="col-md-12">
          <h5>Does the seller know if any of the following arrangements affect the property:</h5>
          <simple-question
            titleNumberOrLetter="(a)"
            :dropdownOptions="yesOrNo"
            labelName="minesAndMineralsLabelName"
            title="Other people’s rights to mines and minerals under the land"
            v-model="minesAndMinerals"
            validationFieldName="mines and minerals"
          ></simple-question>

          <simple-question
            titleNumberOrLetter="(b)"
            :dropdownOptions="yesOrNo"
            labelName="chancelRepairLiabilityLabelName"
            title="Chancel repair liability"
            v-model="chancelRepairLiability"
            validationFieldName="chancel repair liability"
          ></simple-question>

          <simple-question
            titleNumberOrLetter="(c)"
            :dropdownOptions="yesOrNo"
            labelName="resourceRightsLabelName"
            title="Other people’s rights to take things from the land (such as timber, hay or fish)"
            v-model="resourceRights"
            validationFieldName="resource rights"
          ></simple-question>

          <div
            class="col-md-12"
            v-if="resourceRights.answer || chancelRepairLiability.answer || minesAndMinerals.answer"
          >
            <h5><label for="arrangementsDescription" class="mandatory">If Yes, please give details:</label></h5>
            <validation-provider name="arrangements description" rules="min:3|required" v-slot="validationContext">
              <b-form-textarea
                name="arrangementsDescription"
                v-model="arrangementsDescription"
                id="arrangementsDescription"
                rows="4"
                :state="getValidationState(validationContext)"
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
        </div>
        <!-- /question 5 end -->

        <div class="border-top my-3"></div>

        <!-- Question 6 -->
        <div class="col-md-12">
          <open-question
            dropdownName="otherArrangementsDropdownName"
            labelName="otherArrangementsLabelName"
            detailsName="other arrangements details"
            textAreaName="otherArrangementsTextAreaName"
            title="Are there any other rights or arrangements affecting the property? This includes any rights of way."
            descriptionTitle="If Yes, please give details."
            v-model="otherArrangements"
            validationFieldName="other arrangements"
          ></open-question>
        </div>
        <!-- /question 6 end -->

        <div class="border-top my-3"></div>

        <!-- Question 7 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="pipesCrossNeighboursLabelName"
            title="Do any drains, pipes or wires serving the property cross any neighbour’s property?"
            v-model="pipesCrossNeighbours"
            validationFieldName="pipes cross neighbours"
          ></simple-question>
        </div>
        <!-- /question 7 end -->

        <div class="border-top my-3"></div>

        <!-- Question 8 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="neighboursPipesCrossLabelName"
            title="Do any drains, pipes or wires leading to any neighbour’s property cross the property?"
            v-model="neighboursPipesCross"
            validationFieldName="neighbours pipes cross"
          ></simple-question>
        </div>
        <!-- /question 8 end -->

        <div class="border-top my-3"></div>

        <!-- Question 9 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="pipesAgreementLabelName"
            title="Is there any agreement or arrangement about drains, pipes or wires?"
            v-model="pipesAgreement"
            validationFieldName="pipes agreement"
          ></simple-question>
        </div>
        <!-- /question 9 end -->

        <!-- Question 10 -->
        <div v-if="pipesAgreement.answer === 'YNNK_YES'" class="col-md-12">
          <simple-question
            :dropdownOptions="enclosedOrToFollow"
            labelName="pipesAgreementCopyLabelName"
            title="If Yes, please supply a copy or give details:"
            v-model="pipesAgreementCopy"
            validationFieldName="pipes agreement copy"
          ></simple-question>
          <inline-file-uploader
            v-if="pipesAgreementCopy.answer === 'ETF_ENCLOSED'"
            :requiredDocument="rights.pipesAgreementCopy"
          />
        </div>

        <div class="col-md-12" v-if="pipesAgreement.answer === 'YNNK_YES'">
          <h5><label for="pipesAgreementDescription" class="mandatory">If Yes, please give details:</label></h5>
          <validation-provider name="pipes arrangements description" rules="min:3|required" v-slot="validationContext">
            <b-form-textarea
              name="pipesAgreementDescription"
              v-model="pipesAgreementDescription"
              id="pipesAgreementDescription"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
        <!-- /question 10 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
