import axios from 'axios';

export default (
  id,
  noticesReceived,
  noticesReceivedDescription,
  developmentProposals,
  developmentProposalsDescription,
) => {
  const preparedData = {};

  preparedData.notices_received = noticesReceived;
  preparedData.notices_received_description = noticesReceived ? noticesReceivedDescription : '';

  preparedData.development_proposals = developmentProposals;
  preparedData.development_proposals_description = developmentProposals ? developmentProposalsDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    notice: preparedData,
  });
};
