<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
import FormWrapper from '@/components/FormWrapper';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Boundaries from '@/components/forms/Boundaries';
import Disputes from '@/components/forms/Disputes';
import Notices from '@/components/forms/Notices';
import Alterations from '@/components/forms/Alterations';
import Guarantees from '@/components/forms/Guarantees';
import Insurance from '@/components/forms/Insurance';
import EnvironmentalMatters from '@/components/forms/EnvironmentalMatters';
import Rights from '@/components/forms/Rights';
import ParkingDetails from '@/components/forms/ParkingDetails';
import OtherCharges from '@/components/forms/OtherCharges';
import Occupiers from '@/components/forms/Occupiers';
import Services from '@/components/forms/Services';
import UtilityConnections from '@/components/forms/UtilityConnections';
import TransactionInformation from '@/components/forms/TransactionInformation';
import PdfPreview from '@/components/forms/SalePDFpreview';
import AboutTA6 from '@/components/forms/AboutPages/AboutTA6';
import PropertyAndClientDetails from '@/components/forms/PropertyAndClientDetails';
import Modal from '@/components/Modal';
import PostTrackingEvent from '@/api/trackingEvents/postTrackingEvents';
import patchTa6Submitted from '@/api/sales/patchTa6Submitted';
import SummaryPage from '@/components/forms/SummaryPage';
import SaveProgressInfo from '@/components/messaging/SaveProgressInfo';
import FormCompletion from '@/mixins/FormCompletion';

export default {
  name: 'TA6',
  components: {
    PropertyAndClientDetails,
    FormWrapper,
    FormWizard,
    TabContent,
    WizardButton,
    Boundaries,
    Disputes,
    Notices,
    Alterations,
    Guarantees,
    Insurance,
    EnvironmentalMatters,
    Rights,
    ParkingDetails,
    OtherCharges,
    Services,
    Occupiers,
    UtilityConnections,
    TransactionInformation,
    PdfPreview,
    AboutTA6,
    Modal,
    SummaryPage,
    SaveProgressInfo,
  },
  mixins: [FormCompletion],
  data() {
    return {
      ta6Form: 0,
      showSubmitModal: false,
      wizardLoading: false,
      avoidForceRerender: false,
      disabled: false,
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    ...mapActions('sale', ['addTrackingEvent', 'setEventToHandle']),
    setLoading(value) {
      this.wizardLoading = value;
    },
    forceRerender() {
      this.ta6Form++;
    },
    saveBoundaries: function () {
      return this.$refs.boundaries.saveToApi();
    },
    saveDisputes: function () {
      return this.$refs.disputes.saveToApi();
    },
    saveNotices: function () {
      return this.$refs.notices.saveToApi();
    },
    saveAlterations: function () {
      return this.$refs.alterations.saveToApi();
    },
    saveGuarantees: function () {
      return this.$refs.guarantees.saveToApi();
    },
    saveInsurance: function () {
      return this.$refs.insurance.saveToApi();
    },
    saveEnvironmentalMatters: function () {
      return this.$refs.environmentalMatters.saveToApi();
    },
    saveRights: function () {
      return this.$refs.rights.saveToApi();
    },
    saveParkingDetails: function () {
      return this.$refs.parkingDetails.saveToApi();
    },
    saveOtherCharges: function () {
      return this.$refs.otherCharges.saveToApi();
    },
    saveOccupiers: function () {
      return this.$refs.occupiers.saveToApi();
    },
    saveService: function () {
      return this.$refs.service.saveToApi();
    },
    saveUtilityConnections: function () {
      return this.$refs.utilityConnections.saveToApi();
    },
    saveTransactionInformation: function () {
      return this.$refs.transactionInformation.saveToApi();
    },
    checkConfirmation: function () {
      return this.$refs.aboutTa6.confirm();
    },
    showPdfPreview: function (downloadFile = false) {
      this.avoidForceRerender = true;
      return this.$refs.pdfPreview.getPdf(downloadFile, 'ta6-form-');
    },
    saveAndRedirect: async function () {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
    saveAndShowModalBeforeSubmit: async function () {
      this.showSubmitModal = true;
    },
    scrollToTop() {
      if (this.$router.currentRoute.name == 'ViewEntity') {
        this.$scrollTo('.page-header');
      }
    },
    allowForceRerender(value) {
      this.avoidForceRerender = value;
    },
    async submitForm(value) {
      this.showSubmitModal = false;
      if (value) {
        this.disabled = true;

        let metadata = '';
        let eventSlug = 'completed_ta6';

        let trackingEvent = {
          product_slug: this.entityType,
          entity_id: this.entityId,
          event_slug: eventSlug,
          metadata: metadata,
        };

        await Promise.all([
          PostTrackingEvent(trackingEvent),
          patchTa6Submitted({
            id: this.entityId,
          }),
        ])
          .then(() => {
            this.addTrackingEvent(trackingEvent);
            this.$gtag.event('Completed Milestone', {
              event_category: 'Task',
              event_label: 'Completed TA6',
            });

            this.setEventToHandle('completed_ta6');

            this.navigate('ViewEntity', {
              entityType: this.entityType,
              entityId: this.entityId,
            });
          })
          .catch((err) => console.debug(err));

        this.disabled = false;
      }
    },
  },
  watch: {
    loading: function () {
      if (!this.avoidForceRerender) {
        this.forceRerender();
      }
    },
  },
  computed: mapState({
    loading: (state) => state.loading,
  }),
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <div :key="ta6Form">
        <form-wizard
          title=""
          subtitle=""
          stepSize="sm"
          :color="$t('site.formColour')"
          errorColor="#F44336"
          @on-loading="setLoading"
          @on-change="scrollToTop"
        >
          <tab-content title="Details">
            <property-and-client-details />
            <save-progress-info />
          </tab-content>

          <tab-content title="About" :before-change="checkConfirmation">
            <about-t-a6 ref="aboutTa6" :entityType="entityType" :entityId="entityId" />
          </tab-content>

          <tab-content title="Boundaries" :before-change="saveBoundaries">
            <boundaries ref="boundaries"></boundaries>
          </tab-content>

          <tab-content title="Disputes" :before-change="saveDisputes">
            <disputes ref="disputes"></disputes>
          </tab-content>

          <tab-content title="Notices" :before-change="saveNotices">
            <notices ref="notices"></notices>
          </tab-content>

          <tab-content title="Alterations" :before-change="saveAlterations">
            <alterations ref="alterations"></alterations>
          </tab-content>

          <tab-content title="Guarantees" :before-change="saveGuarantees">
            <guarantees ref="guarantees"></guarantees>
          </tab-content>

          <tab-content title="Insurance" :before-change="saveInsurance">
            <insurance ref="insurance"></insurance>
          </tab-content>

          <tab-content title="Environmental" :before-change="saveEnvironmentalMatters">
            <environmental-matters ref="environmentalMatters"></environmental-matters>
          </tab-content>

          <tab-content title="Rights" :before-change="saveRights">
            <rights ref="rights"></rights>
          </tab-content>

          <tab-content title="Parking" :before-change="saveParkingDetails">
            <parking-details ref="parkingDetails"></parking-details>
          </tab-content>

          <tab-content title="Charges" :before-change="saveOtherCharges">
            <other-charges ref="otherCharges"></other-charges>
          </tab-content>

          <tab-content title="Occupiers" :before-change="saveOccupiers">
            <occupiers ref="occupiers"></occupiers>
          </tab-content>

          <tab-content title="Services" :before-change="saveService">
            <services ref="service"></services>
          </tab-content>

          <tab-content title="Connections" :before-change="saveUtilityConnections">
            <utility-connections ref="utilityConnections"></utility-connections>
          </tab-content>

          <tab-content title="Transaction" :before-change="saveTransactionInformation">
            <transaction-information ref="transactionInformation"></transaction-information>
          </tab-content>

          <tab-content title="Summary">
            <summary-page
              ref="summary"
              :disabled="disabled"
              :entityType="entityType"
              @preview="showPdfPreview"
              @download="showPdfPreview(true)"
              @save="saveAndRedirect"
              @submit="saveAndShowModalBeforeSubmit"
            ></summary-page>
          </tab-content>

          <!-- Customised Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left col-sm-12 col-md-6">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                @click.native="props.prevTab()"
                :style="props.fillButtonStyle"
                class="w-100 w-sm-auto bg-light border-dashed text-primary"
                ><i class="icon-arrow-left5"></i> Previous
              </wizard-button>
            </div>
            <div class="wizard-footer-right col-sm-12 col-md-6">
              <div v-if="!props.isLastStep">
                <wizard-button
                  @click.native="props.nextTab()"
                  class="wizard-footer-right w-100 w-sm-auto"
                  :style="props.fillButtonStyle"
                  :disabled="wizardLoading"
                >
                  Save and Continue
                  <i v-if="wizardLoading" class="icon-spinner11 ml-2 spinner"></i>
                  <i v-else class="icon-arrow-right5 ml-2"></i>
                </wizard-button>
              </div>
              <div v-else>
                <pdf-preview
                  form-name="TA6"
                  ref="pdfPreview"
                  :entityType="entityType"
                  :entityId="entityId"
                  @allowForceRerender="allowForceRerender"
                ></pdf-preview>
                <modal
                  v-if="showSubmitModal"
                  closeButtonText="Close"
                  proceedButtonText="Save And Submit"
                  @proceed="submitForm"
                  >Be aware that once you "Save And Submit", the form will be locked for any changes. Do you want submit
                  this form?
                </modal>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </form-wrapper>
</template>
