import axios from 'axios';

export default (
  id,
  buildingWorks,
  buildingWorksDescription,
  changeOfUse,
  changeOfUseYear,
  replacementWindowInstallation,
  replacementWindowInstallationYear,
  conservatoryAddition,
  conservatoryAdditionYear,
  planningPermissionNotRequiredDescription,
  changesUnfinished,
  changesUnfinishedDescription,
  planningPermissionConditionsBreached,
  planningPermissionConditionsBreachedDescription,
  buildingControlIssuesToResolve,
  buildingControlIssuesToResolveDescription,
  solarPanelsInstalled,
  solarPanelsInstalledYear,
  solarPanelsOwned,
  solarPanelsLongLease,
  solarPanelsLongLeaseDocuments,
  listedBuilding,
  conservationArea,
  listedBuildingConservationAreaDocuments,
  treesSubjectToPreservationOrder,
  preservationOrderCompliedWith,
  preservationOrderRelevantDocuments,
) => {
  const preparedData = {};
  const showChangesSection = buildingWorks || changeOfUse || replacementWindowInstallation || conservatoryAddition;
  preparedData.planning_permission_not_required_description =
    showChangesSection && planningPermissionNotRequiredDescription ? planningPermissionNotRequiredDescription : '';
  preparedData.changes_unfinished = showChangesSection ? changesUnfinished : null;
  preparedData.changes_unfinished_description =
    showChangesSection && changesUnfinished ? changesUnfinishedDescription : '';

  preparedData.solar_panels_installed = solarPanelsInstalled;
  preparedData.solar_panels_installed_year = solarPanelsInstalled ? parseInt(solarPanelsInstalledYear) : null;
  preparedData.solar_panels_owned = solarPanelsInstalled ? solarPanelsOwned : null;
  preparedData.solar_panels_long_lease = solarPanelsInstalled ? solarPanelsLongLease : null;
  preparedData.solar_panels_long_lease_documents = solarPanelsLongLease ? solarPanelsLongLeaseDocuments : '';

  preparedData.trees_subject_to_preservation_order = treesSubjectToPreservationOrder;
  preparedData.preservation_order_complied_with =
    treesSubjectToPreservationOrder === 'YNNK_YES' ? preservationOrderCompliedWith : null;
  preparedData.preservation_order_relevant_documents =
    treesSubjectToPreservationOrder === 'YNNK_YES' ? preservationOrderRelevantDocuments : null;

  preparedData.listed_building = listedBuilding;
  preparedData.conservation_area = conservationArea;
  preparedData.listed_building_conservation_area_documents =
    conservationArea === 'YNNK_YES' ? listedBuildingConservationAreaDocuments : null;

  preparedData.building_control_issues_to_resolve = buildingControlIssuesToResolve;
  preparedData.building_control_issues_to_resolve_description = buildingControlIssuesToResolve
    ? buildingControlIssuesToResolveDescription
    : '';

  preparedData.planning_permission_conditions_breached = planningPermissionConditionsBreached;
  preparedData.planning_permission_conditions_breached_description = planningPermissionConditionsBreached
    ? planningPermissionConditionsBreachedDescription
    : '';

  preparedData.building_works = buildingWorks;
  preparedData.building_works_description = buildingWorks ? buildingWorksDescription : '';

  preparedData.conservatory_addition = conservatoryAddition;
  preparedData.conservatory_addition_year = conservatoryAddition ? parseInt(conservatoryAdditionYear) : null;

  preparedData.change_of_use = changeOfUse;
  preparedData.change_of_use_year = changeOfUse ? parseInt(changeOfUseYear) : null;

  preparedData.replacement_window_installation = replacementWindowInstallation;
  preparedData.replacement_window_installation_year = replacementWindowInstallation
    ? parseInt(replacementWindowInstallationYear)
    : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    alteration: preparedData,
  });
};
