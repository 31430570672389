import axios from 'axios';

export default (
  id,
  sharedServiceMaintenance,
  sharedServiceMaintenanceDescription,
  neighbouringRights,
  neighbouringRightsDescription,
  accessPrevention,
  accessPreventionDescription,
  rightsOfLight,
  adjoiningProperties,
  customaryRights,
  minesAndMinerals,
  chancelRepairLiability,
  resourceRights,
  arrangementsDescription,
  otherArrangements,
  otherArrangementsDescription,
  pipesCrossNeighbours,
  neighboursPipesCross,
  pipesAgreement,
  pipesAgreementCopy,
  pipesAgreementDescription,
) => {
  const preparedData = {
    rights_of_light: rightsOfLight,
    adjoining_properties: adjoiningProperties,
    customary_rights: customaryRights,
    mines_and_minerals: minesAndMinerals,
    resource_rights: resourceRights,
    chancel_repair_liability: chancelRepairLiability,
    pipes_cross_neighbours: pipesCrossNeighbours,
    neighbours_pipes_cross: neighboursPipesCross,
  };

  preparedData.pipes_agreement = pipesAgreement;
  preparedData.pipes_agreement_copy = pipesAgreement === 'YNNK_YES' ? pipesAgreementCopy : null;
  preparedData.pipes_agreement_description = pipesAgreement === 'YNNK_YES' ? pipesAgreementDescription : '';

  preparedData.shared_service_maintenance = sharedServiceMaintenance;
  preparedData.shared_service_maintenance_description = sharedServiceMaintenance
    ? sharedServiceMaintenanceDescription
    : '';

  preparedData.neighbouring_rights = neighbouringRights;
  preparedData.neighbouring_rights_description = neighbouringRights ? neighbouringRightsDescription : '';

  preparedData.access_prevention = accessPrevention;
  preparedData.access_prevention_description = accessPrevention ? accessPreventionDescription : '';

  preparedData.other_arrangements = otherArrangements;
  preparedData.other_arrangements_description = otherArrangements ? otherArrangementsDescription : '';

  preparedData.arrangements_description =
    resourceRights || chancelRepairLiability || minesAndMinerals ? arrangementsDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    right: preparedData,
  });
};
