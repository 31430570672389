<script>
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import { mapMutations, mapState } from 'vuex';
import * as insuranceMutations from '@/store/insurance/mutations';
import patchInsurance from '@/api/buildings/patchInsurance';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNoApplicable = {
  YNNA_YES: 'Yes',
  YNNA_NO: 'No',
  YNNA_NO_APPLICABLE: 'N/A',
};

export default {
  name: 'Insurance',
  components: { SimpleQuestion, OpenQuestion },
  computed: {
    ...mapState({
      s_buildingInsured: (state) => state.building.insurance.buildingInsured,
      s_buildingInsuredDetails: (state) => state.building.insurance.buildingInsuredDetails,
      s_landlordInsured: (state) => state.building.insurance.landlordInsured,
      s_premiumRise: (state) => state.building.insurance.premiumRise,
      s_highExcesses: (state) => state.building.insurance.highExcesses,
      s_unusualConditions: (state) => state.building.insurance.unusualConditions,
      s_refused: (state) => state.building.insurance.refused,
      s_insuranceProblemsDescription: (state) => state.building.insurance.insuranceProblemsDescription,
      s_claims: (state) => state.building.insurance.claims,
      s_claimsDescription: (state) => state.building.insurance.claimsDescription,
      s_buildingId: (state) => state.buildingId,
    }),
    buildingInsured: {
      get() {
        return {
          answer: this.s_buildingInsured,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_BUILDING_INSURED}`](
          value.answer,
        );
      },
    },
    buildingInsuredDetails: {
      get() {
        return this.s_buildingInsuredDetails;
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_BUILDING_INSURED_DETAILS}`](
          value,
        );
      },
    },
    landlordInsured: {
      get() {
        return {
          answer: this.s_landlordInsured,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_BUILDING_LANDLORD_INSURED}`](
          value.answer,
        );
      },
    },
    premiumRise: {
      get() {
        return {
          answer: this.s_premiumRise,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_PREMIUM_RISE}`](value.answer);
      },
    },
    highExcesses: {
      get() {
        return {
          answer: this.s_highExcesses,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_HIGH_EXCESSES}`](value.answer);
      },
    },
    unusualConditions: {
      get() {
        return {
          answer: this.s_unusualConditions,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_UNUSUAL_CONDITIONS}`](
          value.answer,
        );
      },
    },
    refused: {
      get() {
        return {
          answer: this.s_refused,
          description: this.s_insuranceProblemsDescription,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_REFUSED}`](value.answer);
        this[
          `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_INSURANCE_PROBLEMS_DESCRIPTION}`
        ](value.description);
      },
    },
    claims: {
      get() {
        return {
          answer: this.s_claims,
          description: this.s_claimsDescription,
        };
      },
      set(value) {
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_CLAIMS}`](value.answer);
        this[`${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_CLAIMS_DESCRIPTION}`](
          value.description,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      yesOrNoOrNoApplicable,
    };
  },
  methods: {
    ...mapMutations([
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_BUILDING_INSURED}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_BUILDING_INSURED_DETAILS}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_BUILDING_LANDLORD_INSURED}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_PREMIUM_RISE}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_HIGH_EXCESSES}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_UNUSUAL_CONDITIONS}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_REFUSED}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_INSURANCE_PROBLEMS_DESCRIPTION}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_CLAIMS}`,
      `${insuranceMutations.INSURANCE_PREFIX}${insuranceMutations.INSURANCE_SET_CLAIMS_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchInsurance(
        this.s_buildingId,
        this.s_buildingInsured,
        this.s_buildingInsuredDetails,
        this.s_landlordInsured,
        this.s_premiumRise,
        this.s_highExcesses,
        this.s_unusualConditions,
        this.s_refused,
        this.s_insuranceProblemsDescription,
        this.s_claims,
        this.s_claimsDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Insurance</h2>

      <div class="border-top my-3"></div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="buildingInsuredLabelName"
            title="Does the seller insure the property?"
            v-model="buildingInsured"
            validationFieldName="building insured"
          ></simple-question>
        </div>
        <!-- /question 1 end -->

        <!-- Question 2 -->
        <div class="col-md-12" v-if="buildingInsured.answer === false">
          <ValidationProvider name="building insured details" rules="required" v-slot="validationContext">
            <h5><label for="buildingInsuredDetails">Why not?</label></h5>

            <b-form-textarea
              v-model="buildingInsuredDetails"
              id="buildingInsuredDetails"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNoApplicable"
            labelName="landlordInsuredLabelName"
            title="If the property is a flat, does the landlord insure the building?"
            v-model="landlordInsured"
            validationFieldName="landlord insured"
          ></simple-question>
        </div>
        <!-- /question 3 end -->

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <h5>Has any buildings insurance taken out by the seller ever been:</h5>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="premiumRiseLabelName"
            titleNumberOrLetter="(a)"
            title="Subject to an abnormal rise in premiums?"
            v-model="premiumRise"
            validationFieldName="premium rise"
          ></simple-question>
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="highExcessesLabelName"
            titleNumberOrLetter="(b)"
            title="Subject to high excesses?"
            v-model="highExcesses"
            validationFieldName="high excesses"
          ></simple-question>
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="unusualConditionsLabelName"
            titleNumberOrLetter="(c)"
            title="Subject to unusual conditions?"
            v-model="unusualConditions"
            validationFieldName="unusual conditions"
          ></simple-question>
          <open-question
            titleNumberOrLetter="(d)"
            dropdownName="refusedDropdownName"
            labelName="refusedLabelName"
            textAreaName="refusedTextAreaName"
            detailsName="refused details"
            descriptionTitle="If Yes, please give details:"
            title="Refused?"
            v-model="refused"
            validationFieldName="refused"
          ></open-question>
        </div>
        <!-- /question 4 end -->

        <div class="border-top my-3"></div>

        <!-- Question 5 -->
        <div class="col-md-12">
          <open-question
            dropdownName="claimsDropdownName"
            labelName="claimsLabelName"
            textAreaName="claimsTextAreaName"
            detailsName="claims details"
            title="Has the seller made any buildings insurance claims?"
            descriptionTitle="If Yes, please give details:"
            v-model="claims"
            validationFieldName="claims"
          ></open-question>
        </div>
        <!-- /question 5 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
