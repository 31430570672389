import axios from 'axios';

export default (
  id,
  buildingInsured,
  buildingInsuredDetails,
  landlordInsured,
  premiumRise,
  highExcesses,
  unusualConditions,
  refused,
  insuranceProblemsDescription,
  claims,
  claimsDescription,
) => {
  const preparedData = {
    landlord_insured: landlordInsured,
    premium_rise: premiumRise,
    high_excesses: highExcesses,
    unusual_conditions: unusualConditions,
  };

  preparedData.building_insured = buildingInsured;
  preparedData.building_insured_details = !buildingInsured ? buildingInsuredDetails : '';

  preparedData.refused = refused;
  preparedData.insurance_problems_description = refused ? insuranceProblemsDescription : '';

  preparedData.claims = claims;
  preparedData.claims_description = claims ? claimsDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    insurance: preparedData,
  });
};
