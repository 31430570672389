<script>
import { mapMutations, mapState } from 'vuex';
import * as otherChargesMutations from '@/store/otherCharges/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import patchOtherCharges from '@/api/buildings/patchOtherCharges';

/**
 * Options yes or no.
 *
 * @type {{true: string, false: string}}
 */
const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'OtherCharges',
  components: { OpenQuestion },
  computed: {
    ...mapState({
      s_propertyRelatedCharges: (state) => state.building.otherCharges.propertyRelatedCharges,
      s_propertyRelatedChargesDetails: (state) => state.building.otherCharges.propertyRelatedChargesDetails,
      s_buildingId: (state) => state.buildingId,
    }),
    propertyRelatedCharges: {
      get() {
        return {
          answer: this.s_propertyRelatedCharges,
          description: this.s_propertyRelatedChargesDetails,
        };
      },
      set(value) {
        this[
          `${otherChargesMutations.OTHER_CHARGES_PREFIX}${otherChargesMutations.OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES}`
        ](value.answer);
        this[
          `${otherChargesMutations.OTHER_CHARGES_PREFIX}${otherChargesMutations.OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION}`
        ](value.description);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
    };
  },
  methods: {
    ...mapMutations([
      `${otherChargesMutations.OTHER_CHARGES_PREFIX}${otherChargesMutations.OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES}`,
      `${otherChargesMutations.OTHER_CHARGES_PREFIX}${otherChargesMutations.OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchOtherCharges(this.s_buildingId, this.s_propertyRelatedCharges, this.s_propertyRelatedChargesDetails);

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Other charges</h2>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <div class="section-note">
            <p>
              <b>Note:</b> If the property is leasehold, details of lease expenses such as service charges and ground
              rent should be set out on the separate TA7 Leasehold Information Form. If the property is freehold, there
              may still be charges: for example, payments to a management company or for the use of a private drainage
              system.
            </p>
          </div>
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <open-question
            dropdownName="propertyRelatedChargesDropdownName"
            textAreaName="propertyRelatedChargesTextAreaName"
            :dropdownOptions="yesOrNo"
            labelName="propertyRelatedChargesLabelName"
            title="Does the seller have to pay any charges relating to the property (excluding any payments such as council tax,
                        utility charges, etc.), for example payments to a management company?"
            descriptionTitle="If Yes, please give details: "
            v-model="propertyRelatedCharges"
            validationFieldName="property related charges"
          />
        </div>
        <!-- /question 2 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
