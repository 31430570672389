<script>
import { mapMutations, mapState } from 'vuex';
import * as disputesMutations from '@/store/disputes/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import patchDisputes from '@/api/buildings/patchDisputes';

export default {
  components: { OpenQuestion },
  name: 'Disputes',
  computed: {
    ...mapState({
      s_disputesComplaints: (state) => state.building.disputes.disputesComplaints,
      s_disputesComplaintsDescription: (state) => state.building.disputes.disputesComplaintsDescription,
      s_potentialDispute: (state) => state.building.disputes.potentialDispute,
      s_potentialDisputeDescription: (state) => state.building.disputes.potentialDisputeDescription,
      s_buildingId: (state) => state.buildingId,
    }),
    disputesComplaints: {
      get() {
        return {
          answer: this.s_disputesComplaints,
          description: this.s_disputesComplaintsDescription,
        };
      },
      set(value) {
        this[`${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_COMPLAINTS}`](value.answer);
        this[`${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_COMPLAINTS_DESCRIPTION}`](
          value.description,
        );
      },
    },
    potentialDispute: {
      get() {
        return {
          answer: this.s_potentialDispute,
          description: this.s_potentialDisputeDescription,
        };
      },
      set(value) {
        this[`${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_POTENTIAL}`](value.answer);
        this[`${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_POTENTIAL_DESCRIPTION}`](
          value.description,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    ...mapMutations([
      `${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_COMPLAINTS}`,
      `${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_COMPLAINTS_DESCRIPTION}`,
      `${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_POTENTIAL}`,
      `${disputesMutations.DISPUTES_PREFIX}${disputesMutations.DISPUTES_SET_POTENTIAL_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchDisputes(
        this.s_buildingId,
        this.s_disputesComplaints,
        this.s_disputesComplaintsDescription,
        this.s_potentialDispute,
        this.s_potentialDisputeDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error))
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Disputes and complaints</h2>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <open-question
            detailsName="disputes complaints details"
            dropdownName="disputesComplaintsDropdownName"
            labelName="disputesComplaintsLabelName"
            textAreaName="disputesComplaintsTextAreaName"
            title="Have there been any disputes or complaints regarding this property or a property nearby?"
            descriptionTitle="If Yes, please give details:"
            v-model="disputesComplaints"
            validationFieldName="disputes complaints"
          ></open-question>
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <open-question
            dropdownName="potentialDisputeDropdownName"
            labelName="potentialDisputeLabelName"
            textAreaName="potentialDisputeTextAreaName"
            detailsName="potential dispute details"
            title="Is the seller aware of anything which might lead to a dispute about the property or a property nearby?"
            descriptionTitle="If Yes, please give details:"
            v-model="potentialDispute"
            validationFieldName="potential dispute"
          ></open-question>
        </div>
        <!-- /question 2 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
