<script>
import { mapMutations, mapState } from 'vuex';
import * as utilityConnectionsMutations from '@/store/utility-connections/mutations';
import OpenQuestionWithDynamicInputFields from '@/components/forms/inputGroups/OpenQuestionWithDynamicInputFields';
import patchUtilityConnections from '@/api/buildings/patchUtilityConnections';

export default {
  name: 'UtilityConnections',
  components: { OpenQuestionWithDynamicInputFields },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.buildingId,
      s_electricity: (state) => state.building.utilityConnections.electricity,
      s_electricityProviderName: (state) => state.building.utilityConnections.electricityProviderName,
      s_electricityLocationOfMeter: (state) => state.building.utilityConnections.electricityLocationOfMeter,
      s_gas: (state) => state.building.utilityConnections.gas,
      s_gasProviderName: (state) => state.building.utilityConnections.gasProviderName,
      s_gasLocationOfMeter: (state) => state.building.utilityConnections.gasLocationOfMeter,
      s_water: (state) => state.building.utilityConnections.water,
      s_waterProviderName: (state) => state.building.utilityConnections.waterProviderName,
      s_waterLocationOfStopcock: (state) => state.building.utilityConnections.waterLocationOfStopcock,
      s_waterLocationOfMeter: (state) => state.building.utilityConnections.waterLocationOfMeter,
      s_sewerage: (state) => state.building.utilityConnections.sewerage,
      s_sewerageProviderName: (state) => state.building.utilityConnections.sewerageProviderName,
      s_telephone: (state) => state.building.utilityConnections.telephone,
      s_telephoneProviderName: (state) => state.building.utilityConnections.telephoneProviderName,
      s_cable: (state) => state.building.utilityConnections.cable,
      s_cableProviderName: (state) => state.building.utilityConnections.cableProviderName,
    }),
    electricity: {
      get() {
        return {
          openQuestion: this.s_electricity,
          dynamicFields: [
            {
              key: 'electricityProviderName',
              label: 'Providers’ name',
              type: 'text',
              value: this.s_electricityProviderName,
              validationName: 'electricity provider name',
              validationRules: ['required'],
            },
            {
              key: 'electricityLocationOfMeter',
              label: 'Location of meter',
              type: 'text',
              value: this.s_electricityLocationOfMeter,
              validationName: 'electricity location of meter',
              validationRules: ['required'],
            },
          ],
        };
      },
      set(value) {
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_ELECTRICITY}`
        ](value.openQuestion);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_ELECTRICITY_PROVIDER_NAME}`
        ](value.dynamicFields.electricityProviderName);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_ELECTRICITY_LOCATION_OF_METER}`
        ](value.dynamicFields.electricityLocationOfMeter);
      },
    },
    gas: {
      get() {
        return {
          openQuestion: this.s_gas,
          dynamicFields: [
            {
              key: 'gasProviderName',
              label: 'Provider’s name',
              type: 'text',
              value: this.s_gasProviderName,
              validationName: 'gas provider name',
              validationRules: ['required'],
            },
            {
              key: 'gasLocationOfMeter',
              label: 'Location of meter',
              type: 'text',
              value: this.s_gasLocationOfMeter,
              validationName: 'gas location of meter',
              validationRules: ['required'],
            },
          ],
        };
      },
      set(value) {
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_GAS}`
        ](value.openQuestion);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_GAS_PROVIDER_NAME}`
        ](value.dynamicFields.gasProviderName);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_GAS_LOCATION_OF_METER}`
        ](value.dynamicFields.gasLocationOfMeter);
      },
    },
    water: {
      get() {
        return {
          openQuestion: this.s_water,
          dynamicFields: [
            {
              key: 'waterProviderName',
              label: 'Provider’s name',
              type: 'text',
              value: this.s_waterProviderName,
              validationName: 'water provider name',
              validationRules: ['required'],
            },
            {
              key: 'waterLocationOfStopcock',
              label: 'Location of stopcock',
              type: 'text',
              value: this.s_waterLocationOfStopcock,
              validationName: 'water location of stopcock',
              validationRules: ['required'],
            },
            {
              key: 'waterLocationOfMeter',
              label: 'Location of meter, if any',
              type: 'text',
              value: this.s_waterLocationOfMeter,
              validationName: 'water location of meter',
              validationRules: [],
            },
          ],
        };
      },
      set(value) {
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER}`
        ](value.openQuestion);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER_PROVIDER_NAME}`
        ](value.dynamicFields.waterProviderName);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_STOPCOCK}`
        ](value.dynamicFields.waterLocationOfStopcock);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_METER}`
        ](value.dynamicFields.waterLocationOfMeter);
      },
    },
    sewerage: {
      get() {
        return {
          openQuestion: this.s_sewerage,
          dynamicFields: [
            {
              key: 'sewerageProviderName',
              label: 'Provider’s name',
              type: 'text',
              value: this.s_sewerageProviderName,
              validationName: 'sewerage provider name',
              validationRules: ['required'],
            },
          ],
        };
      },
      set(value) {
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_SEWERAGE}`
        ](value.openQuestion);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_SEWERAGE_PROVIDER_NAME}`
        ](value.dynamicFields.sewerageProviderName);
      },
    },
    telephone: {
      get() {
        return {
          openQuestion: this.s_telephone,
          dynamicFields: [
            {
              key: 'telephoneProviderName',
              label: 'Provider’s name',
              type: 'text',
              value: this.s_telephoneProviderName,
              validationName: 'telephone provider name',
              validationRules: ['required'],
            },
          ],
        };
      },
      set(value) {
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_TELEPHONE}`
        ](value.openQuestion);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_TELEPHONE_PROVIDER_NAME}`
        ](value.dynamicFields.telephoneProviderName);
      },
    },
    cable: {
      get() {
        return {
          openQuestion: this.s_cable,
          dynamicFields: [
            {
              key: 'cableProviderName',
              label: 'Provider’s name',
              type: 'text',
              value: this.s_cableProviderName,
              validationName: 'cable provider name',
              validationRules: ['required'],
            },
          ],
        };
      },
      set(value) {
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_CABLE}`
        ](value.openQuestion);
        this[
          `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_CABLE_PROVIDER_NAME}`
        ](value.dynamicFields.cableProviderName);
      },
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    ...mapMutations([
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_ELECTRICITY}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_ELECTRICITY_PROVIDER_NAME}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_ELECTRICITY_LOCATION_OF_METER}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_GAS}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_GAS_PROVIDER_NAME}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_GAS_LOCATION_OF_METER}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER_PROVIDER_NAME}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_STOPCOCK}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_METER}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_SEWERAGE}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_SEWERAGE_PROVIDER_NAME}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_TELEPHONE}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_TELEPHONE_PROVIDER_NAME}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_CABLE}`,
      `${utilityConnectionsMutations.UTILITY_CONNECTIONS_PREFIX}${utilityConnectionsMutations.UTILITY_CONNECTIONS_SET_CABLE_PROVIDER_NAME}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchUtilityConnections(
        this.s_buildingId,
        this.s_electricity,
        this.s_electricityProviderName,
        this.s_electricityLocationOfMeter,
        this.s_gas,
        this.s_gasProviderName,
        this.s_gasLocationOfMeter,
        this.s_water,
        this.s_waterProviderName,
        this.s_waterLocationOfStopcock,
        this.s_waterLocationOfMeter,
        this.s_sewerage,
        this.s_sewerageProviderName,
        this.s_telephone,
        this.s_telephoneProviderName,
        this.s_cable,
        this.s_cableProviderName,
      );
      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Connection to utilities and services</h2>

      <div class="col-md-12 text-uppercase">
        <p>
          Please mark the Yes or No boxes to show which of the following utilities and services are connected to the
          property and give details of any providers.
        </p>
      </div>

      <div class="border-top my-3"></div>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <div class="row">
          <!-- Mains  electricity-->
          <div class="col-md-6">
            <open-question-with-dynamic-input-fields
              v-model="electricity"
              open-question-label="Mains electricity"
            ></open-question-with-dynamic-input-fields>
          </div>
          <!-- /mains  electricity-->

          <!-- Mains gas -->
          <div class="col-md-6">
            <open-question-with-dynamic-input-fields
              v-model="gas"
              open-question-label="Mains gas"
            ></open-question-with-dynamic-input-fields>
          </div>
          <!-- /mains gas -->
        </div>

        <div class="border-top my-3"></div>

        <div class="row">
          <!-- Mains water -->
          <div class="col-md-6">
            <open-question-with-dynamic-input-fields
              v-model="water"
              open-question-label="Mains water"
            ></open-question-with-dynamic-input-fields>
          </div>
          <!-- /mains water -->

          <!-- Mains sewerage -->
          <div class="col-md-6">
            <open-question-with-dynamic-input-fields
              v-model="sewerage"
              open-question-label="Mains sewerage"
            ></open-question-with-dynamic-input-fields>
          </div>
          <!-- /mains sewerage -->
        </div>

        <div class="border-top my-3"></div>

        <div class="row">
          <!-- Telephone -->
          <div class="col-md-6">
            <open-question-with-dynamic-input-fields
              v-model="telephone"
              open-question-label="Telephone"
            ></open-question-with-dynamic-input-fields>
          </div>
          <!-- /telephone -->

          <!-- Cable -->
          <div class="col-md-6">
            <open-question-with-dynamic-input-fields
              v-model="cable"
              open-question-label="Cable"
            ></open-question-with-dynamic-input-fields>
          </div>
          <!-- /cable -->
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
