<script>
import { mapMutations, mapState } from 'vuex';
import * as boundaryMutations from '@/store/boundaries/mutations';
import BoundaryArea from '@/components/forms/inputGroups/BoundaryArea';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import OpenQuestionWithAdditionals from '@/components/forms/inputGroups/OpenQuestionWithAdditionals';
import patchBoundaries from '@/api/buildings/patchBoundaries';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import boundaries from '@/functions/supportingDocuments/boundaries';

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

export default {
  name: 'Boundaries',
  components: { BoundaryArea, OpenQuestion, OpenQuestionWithAdditionals, InlineFileUploader },
  computed: {
    ...mapState({
      s_boundaryLeft: (state) => state.building.boundary.boundaryLeft,
      s_boundaryRight: (state) => state.building.boundary.boundaryRight,
      s_boundaryFront: (state) => state.building.boundary.boundaryFront,
      s_boundaryRear: (state) => state.building.boundary.boundaryRear,
      s_irregular: (state) => state.building.boundary.irregular,
      s_featureMoved: (state) => state.building.boundary.featureMoved,
      s_featureMovedDescription: (state) => state.building.boundary.featureMovedDescription,
      s_landSoldPurchased: (state) => state.building.boundary.landSoldPurchased,
      s_landSoldPurchasedDescription: (state) => state.building.boundary.landSoldPurchasedDescription,
      s_overhangProjectUnder: (state) => state.building.boundary.overhangProjectUnder,
      s_overhangProjectUnderDescription: (state) => state.building.boundary.overhangProjectUnderDescription,
      s_partyWallAct: (state) => state.building.boundary.partyWallAct,
      s_partyWallActNotice: (state) => state.building.boundary.partyWallActNotice,
      s_partyWallActDescription: (state) => state.building.boundary.partyWallActDescription,
      s_buildingId: (state) => state.buildingId,
      s_building: (state) => state.building,
    }),
    boundaryLeft: {
      get() {
        return this.s_boundaryLeft;
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_LEFT}`](value);
      },
    },
    boundaryRight: {
      get() {
        return this.s_boundaryRight;
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_RIGHT}`](value);
      },
    },
    boundaryFront: {
      get() {
        return this.s_boundaryFront;
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_FRONT}`](value);
      },
    },
    boundaryRear: {
      get() {
        return this.s_boundaryRear;
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_REAR}`](value);
      },
    },
    irregular: {
      get() {
        return this.s_irregular;
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_IRREGULAR}`](value);
      },
    },
    featureMoved: {
      get() {
        return {
          answer: this.s_featureMoved,
          description: this.s_featureMovedDescription,
        };
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_FEATURE_MOVED}`](value.answer);
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_FEATURE_MOVED_DESCRIPTION}`](
          value.description,
        );
      },
    },
    landSoldPurchased: {
      get() {
        return {
          answer: this.s_landSoldPurchased,
          description: this.s_landSoldPurchasedDescription,
        };
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_LAND_SOLD_PURCHASED}`](
          value.answer,
        );
        this[
          `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_LAND_SOLD_PURCHASED_DESCRIPTION}`
        ](value.description);
      },
    },
    overhangProjectUnder: {
      get() {
        return {
          answer: this.s_overhangProjectUnder,
          description: this.s_overhangProjectUnderDescription,
        };
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER}`](
          value.answer,
        );
        this[
          `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION}`
        ](value.description);
      },
    },
    overhangProjectUnderDescription: {
      get() {
        return this.s_overhangProjectUnderDescription;
      },
      set(value) {
        this[
          `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION}`
        ](value);
      },
    },
    partyWallAct: {
      get() {
        return {
          answer: this.s_partyWallAct,
          additionalAnswer: this.s_partyWallActNotice,
          description: this.s_partyWallActDescription,
        };
      },
      set(value) {
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_PARTY_WALL_ACT}`](value.answer);
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_PARTY_WALL_ACT_NOTICE}`](
          value.additionalAnswer,
        );
        this[`${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_PARTY_WALL_ACT_DESCRIPTION}`](
          value.description,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      enclosedOrToFollow,
      boundaries,
    };
  },
  methods: {
    ...mapMutations([
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_LEFT}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_RIGHT}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_FRONT}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_REAR}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_IRREGULAR}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_FEATURE_MOVED}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_FEATURE_MOVED_DESCRIPTION}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_LAND_SOLD_PURCHASED}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_LAND_SOLD_PURCHASED_DESCRIPTION}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_PARTY_WALL_ACT}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_PARTY_WALL_ACT_NOTICE}`,
      `${boundaryMutations.BOUNDARIES_PREFIX}${boundaryMutations.BOUNDARIES_SET_PARTY_WALL_ACT_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchBoundaries(
        this.s_buildingId,
        this.s_boundaryLeft,
        this.s_boundaryRight,
        this.s_boundaryFront,
        this.s_boundaryRear,
        this.s_irregular,
        this.s_featureMoved,
        this.s_featureMovedDescription,
        this.s_landSoldPurchased,
        this.s_landSoldPurchasedDescription,
        this.s_overhangProjectUnder,
        this.s_overhangProjectUnderDescription,
        this.s_partyWallAct,
        this.s_partyWallActNotice,
        this.s_partyWallActDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      //  .catch((error) => console.error(error));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Boundaries</h2>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12 my-3">
          <h5>
            Looking towards the property from the road, who owns or accepts responsibility to maintain or repair the
            boundary features
          </h5>
        </div>

        <div class="row col-md-12">
          <boundary-area
            class="col-md-6"
            label="On the left?"
            v-model="boundaryLeft"
            validationFieldName="left boundary feature"
          />

          <boundary-area
            class="col-md-6"
            label="On the right?"
            v-model="boundaryRight"
            validationFieldName="right boundary feature"
          />
        </div>

        <div class="row col-md-12">
          <boundary-area
            class="col-md-6"
            label="At the rear?"
            v-model="boundaryRear"
            validationFieldName="rear boundary feature"
          />

          <boundary-area
            class="col-md-6"
            label="At the front?"
            v-model="boundaryFront"
            validationFieldName="front boundary feature"
          />
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12 my-3">
          <h5>
            <label for="irregular"
              >If the boundaries are irregular please indicate ownership by written description or by reference to a
              plan:</label
            >
          </h5>
        </div>

        <div class="col-md-12 my-3">
          <validation-provider name="irregular" rules="" v-slot="validationContext">
            <b-form-textarea
              v-model="irregular"
              id="irregular"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <open-question
            additionalDropdownName="featureMovedAdditionalDropdownName"
            dropdownName="featureMovedDropdownName"
            labelName="featureMovedLabelName"
            textAreaName="featureMovedTextAreaName"
            detailsName="boundary feature having been moved details"
            title="Is the seller aware of any boundary feature having been moved in the last 10 years
                            or during the seller’s period of ownership if longer?"
            v-model="featureMoved"
            descriptionTitle="If Yes, please give details:"
            validationFieldName="boundary feature having been moved"
          ></open-question>
        </div>
        <!-- /question 3 end -->

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <open-question
            additionalDropdownName="landSoldPurchasedAdditionalDropdownName"
            dropdownName="landSoldPurchasedDropdownName"
            labelName="landSoldPurchasedLabelName"
            detailsName="land previously forming part details"
            textAreaName="landSoldPurchasedTextAreaName"
            title="During the seller’s ownership, has any adjacent land or property been purchased by
                            the seller?"
            descriptionTitle="If Yes, please give details:"
            v-model="landSoldPurchased"
            validationFieldName="land previously forming part"
          ></open-question>
        </div>
        <!-- /question 4 end -->

        <div class="border-top my-3"></div>

        <!-- Question 5 -->
        <div class="col-md-12">
          <open-question
            additionalDropdownName="overhangProjectUnderAdditionalDropdownName"
            dropdownName="overhangProjectUnderDropdownName"
            labelName="overhangProjectUnderLabelName"
            textAreaName="overhangProjectUnderTextAreaName"
            detailsName="boundary of the neighbouring property or road details"
            title="Does any part of the property or any building on the property overhang, or project under,
                            the boundary of the neighbouring property or road, for example cellars under the pavement,
                            overhanging eaves or covered walkways?"
            descriptionTitle="If Yes, please give details:"
            v-model="overhangProjectUnder"
            validationFieldName="boundary of the neighbouring property or road"
          ></open-question>
        </div>
        <!-- /question 5 end -->

        <div class="border-top my-3"></div>

        <!-- Question 6 -->
        <div class="col-md-12">
          <open-question-with-additionals
            :additional-answers="enclosedOrToFollow"
            additionalDropdownName="partyWallActAdditionalDropdownName"
            dropdownName="partyWallActDropdownName"
            labelName="partyWallActLabelName"
            textAreaName="partyWallActTextAreaName"
            title="Has any notice been received under the Party Wall Act 1996 in respect of any shared/party boundaries?"
            descriptionTitle="If Yes, please supply a copy, and give details of any works carried out or agreed:"
            v-model="partyWallAct"
            validationFieldName="Party Wall Act 1996 in respect of any shared/party boundaries"
          >
            <inline-file-uploader
              v-if="partyWallAct.additionalAnswer === 'ETF_ENCLOSED'"
              :requiredDocument="boundaries.partyWallAct"
            />
          </open-question-with-additionals>
        </div>
        <!-- /question 6 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
