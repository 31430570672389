import axios from 'axios';

export default (
  id,
  boundaryLeft,
  boundaryRight,
  boundaryFront,
  boundaryRear,
  irregular,
  featureMoved,
  featureMovedDescription,
  landSoldPurchased,
  landSoldPurchasedDescription,
  overhangProjectUnder,
  overhangProjectUnderDescription,
  partyWallAct,
  partyWallActNotice,
  partyWallActDescription,
) => {
  const preparedData = {
    boundary_left: boundaryLeft,
    boundary_right: boundaryRight,
    boundary_front: boundaryFront,
    boundary_rear: boundaryRear,
  };

  preparedData.irregular = irregular;

  preparedData.feature_moved = featureMoved;
  preparedData.feature_moved_description = featureMoved ? featureMovedDescription : '';

  preparedData.land_sold_purchased = landSoldPurchased;
  preparedData.land_sold_purchased_description = landSoldPurchased ? landSoldPurchasedDescription : '';

  preparedData.overhang_project_under = overhangProjectUnder;
  preparedData.overhang_project_under_description = overhangProjectUnder ? overhangProjectUnderDescription : '';

  preparedData.party_wall_act = partyWallAct;
  preparedData.party_wall_act_notice = partyWallAct ? partyWallActNotice : null;
  preparedData.party_wall_act_description = partyWallAct ? partyWallActDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    boundary: preparedData,
  });
};
