import axios from 'axios';

export default (
  id,
  electricsTested,
  electricsTestedYear,
  electricsTestedCertificate,
  electricsRewired,
  electricalSafetyCertificate,
  complianceCertificate,
  completionCertificate,
  centralHeatingSystem,
  centralHeatingSystemType,
  centralHeatingInstallationDate,
  septicTankReplaced,
  centralHeatingBeforeSpecifiedDate,
  heatingSystemInWorkingOrder,
  heatingSystemServicedYear,
  heatingSystemServicedInspectionReport,
  foulWaterDrainage,
  surfaceWaterDrainage,
  sewerageSepticTank,
  sewerageTreatmentPlant,
  sewerageCesspool,
  sewerageSystemShared,
  sewerageSystemSharedNumber,
  sewerageSystemLastEmptied,
  sewerageSystemServicedYear,
  sewerageSystemInstalledYear,
  sewerageSystemOutsideOfProperty,
  sewerageSystemOutsideOfPropertyDocuments,
) => {
  const preparedData = {};

  preparedData.surface_water_drainage = surfaceWaterDrainage;
  preparedData.foul_water_drainage = foulWaterDrainage;

  const updateSewerageSection =
    surfaceWaterDrainage === 'YNNK_NO' ||
    surfaceWaterDrainage === 'YNNK_NOT_KNOWN' ||
    foulWaterDrainage === 'YNNK_NO' ||
    foulWaterDrainage === 'YNNK_NOT_KNOWN';

  preparedData.sewerage_septic_tank = updateSewerageSection ? sewerageSepticTank : null;
  preparedData.septic_tank_replaced = updateSewerageSection && sewerageSepticTank ? septicTankReplaced : null;
  preparedData.sewerage_treatment_plant = updateSewerageSection ? sewerageTreatmentPlant : null;
  preparedData.sewerage_cesspool = updateSewerageSection ? sewerageCesspool : null;

  const updateSewerageSystem =
    sewerageSepticTank === true || sewerageTreatmentPlant === true || sewerageCesspool === true;

  preparedData.sewerage_system_shared = updateSewerageSection && updateSewerageSystem ? sewerageSystemShared : null;
  preparedData.sewerage_system_shared_number =
    updateSewerageSection && updateSewerageSystem && sewerageSystemShared
      ? parseInt(sewerageSystemSharedNumber, 10)
      : null;
  preparedData.sewerage_system_last_emptied =
    updateSewerageSection && updateSewerageSystem ? sewerageSystemLastEmptied : null;
  preparedData.sewerage_system_serviced_year =
    updateSewerageSection && updateSewerageSystem && sewerageTreatmentPlant ? sewerageSystemServicedYear : null;
  preparedData.sewerage_system_installed_year =
    updateSewerageSection && updateSewerageSystem ? sewerageSystemInstalledYear : null;
  preparedData.sewerage_system_outside_of_property =
    updateSewerageSection && updateSewerageSystem ? sewerageSystemOutsideOfProperty : null;
  preparedData.sewerage_system_outside_of_property_documents =
    updateSewerageSection && updateSewerageSystem && sewerageSystemOutsideOfProperty
      ? sewerageSystemOutsideOfPropertyDocuments
      : null;

  preparedData.central_heating_system = centralHeatingSystem;
  preparedData.central_heating_system_type = centralHeatingSystem ? centralHeatingSystemType : null;
  preparedData.central_heating_installation_date = centralHeatingSystem ? centralHeatingInstallationDate : null;
  preparedData.central_heating_before_specified_date = centralHeatingSystem ? centralHeatingBeforeSpecifiedDate : null;
  preparedData.heating_system_in_working_order = centralHeatingSystem ? heatingSystemInWorkingOrder : null;
  preparedData.heating_system_serviced_year = centralHeatingSystem ? heatingSystemServicedYear : null;
  preparedData.heating_system_serviced_inspection_report = centralHeatingSystem
    ? heatingSystemServicedInspectionReport
    : null;

  preparedData.electrics_tested = electricsTested;
  preparedData.electrics_tested_year = electricsTested ? electricsTestedYear : null;
  preparedData.electrics_tested_certificate = electricsTested ? electricsTestedCertificate : null;
  preparedData.electrics_rewired = electricsRewired;
  preparedData.electrical_safety_certificate = electricsRewired === 'YNNK_YES' ? electricalSafetyCertificate : null;
  preparedData.compliance_certificate = electricsRewired === 'YNNK_YES' ? complianceCertificate : null;
  preparedData.completion_certificate = electricsRewired === 'YNNK_YES' ? completionCertificate : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    service: preparedData,
  });
};
