<script>
import reduce from 'lodash/reduce';

export default {
  name: 'OpenQuestionWithDynamicInputFields',
  props: {
    openQuestionLabel: {
      type: String,
      required: true,
    },
    openQuestionOptions: {
      type: Object,
      required: false,
      default: () => ({
        true: 'Yes',
        false: 'No',
      }),
    },
    value: {
      type: Object,
      default: () => ({
        openQuestion: '',
        dynamicFields: [
          {
            key: 'someUniqueKey',
            label: 'Field Name',
            type: 'text',
            value: 'asd',
            validationName: 'unique field Name',
            validationRules: ['required'],
          },
        ],
      }),
    },
  },
  methods: {
    triggerUpdate() {
      /*
       * @TODO: Fix this properly when it's not dark and I'm not hopped up on too much coffee
       * There is some complex code here that I've spent a while trying to rewrite but in the face of a looming deadline lets just wrap it in a slight timeout
       */
      setTimeout(() => {
        const args = {
          openQuestion: this.$refs.openQuestionRef.value === 'true' || this.$refs.openQuestionRef.value === true,
        };
        if (this.$refs.dynamicInputFieldsRef) {
          args.dynamicFields = this.$refs.dynamicInputFieldsRef.reduce((carry, inputField) => {
            return Object.assign(carry, { [inputField.id]: inputField.value });
          }, {});
        } else {
          args.dynamicFields = this.value.dynamicFields.reduce((carry, field) => {
            return Object.assign(carry, { [field.key]: field.value });
          }, {});
        }
        this.$emit('input', args);
      }, 100);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  computed: {
    dropdownComputedOptions: function () {
      return reduce(
        this.openQuestionOptions,
        (accumulator, value, key) => {
          const option = {
            value: key,
            text: value,
          };
          accumulator.push(option);
          return accumulator;
        },
        [{ value: null, text: 'Please choose', disabled: true, selected: true }],
      );
    },
  },
};
</script>

<template>
  <div>
    <validation-provider :name="`${openQuestionLabel} question`" rules="required" v-slot="validationContext">
      <div class="form-group">
        <h5>
          <label :for="openQuestionLabel" class="mandatory">{{ openQuestionLabel }}</label>
        </h5>
        <b-form-select
          :options="dropdownComputedOptions"
          :state="getValidationState(validationContext)"
          aria-describedby="select-error"
          class="form-control bg-white"
          :name="openQuestionLabel"
          v-model="value.openQuestion"
          @change="triggerUpdate"
          ref="openQuestionRef"
        ></b-form-select>
        <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="select-error"
          >{{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </div>
    </validation-provider>

    <template v-for="item in value.dynamicFields">
      <validation-provider
        v-if="value.openQuestion"
        :name="item.validationName"
        :rules="item.validationRules.join('|')"
        v-slot="validationContext"
        v-bind:key="item.key"
      >
        <div class="form-group">
          <h5>
            <label :for="item.key" :class="item.validationRules.includes('required') ? 'mandatory' : ''">{{
              item.label
            }}</label>
          </h5>
          <b-form-input
            :name="item.key"
            v-model="item.value"
            :id="item.key"
            ref="dynamicInputFieldsRef"
            :state="getValidationState(validationContext)"
            @input="triggerUpdate"
          ></b-form-input>
          <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="input-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </validation-provider>
    </template>
  </div>
</template>
