<script>
import { mapMutations, mapState } from 'vuex';
import * as servicesMutations from '@/store/services/mutations';
import FreeTypeYearQuestion from '@/components/forms/inputGroups/FreeTypeYearQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchServices from '@/api/buildings/patchServices';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import services from '@/functions/supportingDocuments/services';

const enclosedOrToFollowNotApplicable = {
  ETFNA_ENCLOSED: 'Attached',
  ETFNA_TO_FOLLOW: 'To follow',
  ETFNA_NOT_APPLICABLE: 'Not Applicable',
};

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnown = {
  YNNK_YES: 'Yes',
  YNNK_NO: 'No',
  YNNK_NOT_KNOWN: 'Not Known',
};

export default {
  name: 'Services',
  components: { SimpleQuestion, FreeTypeYearQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.buildingId,
      s_electricsTested: (state) => state.building.services.electricsTested,
      s_electricsTestedYear: (state) => state.building.services.electricsTestedYear,
      s_electricsTestedCertificate: (state) => state.building.services.electricsTestedCertificate,
      s_electricsRewired: (state) => state.building.services.electricsRewired,
      s_electricalSafetyCertificate: (state) => state.building.services.electricalSafetyCertificate,
      s_complianceCertificate: (state) => state.building.services.complianceCertificate,
      s_completionCertificate: (state) => state.building.services.completionCertificate,
      s_centralHeatingSystem: (state) => state.building.services.centralHeatingSystem,
      s_centralHeatingSystemType: (state) => state.building.services.centralHeatingSystemType,
      s_centralHeatingInstallationDate: (state) => state.building.services.centralHeatingInstallationDate,
      s_septicTankReplaced: (state) => state.building.services.septicTankReplaced,
      s_centralHeatingBeforeSpecifiedDate: (state) => state.building.services.centralHeatingBeforeSpecifiedDate,
      s_heatingSystemInWorkingOrder: (state) => state.building.services.heatingSystemInWorkingOrder,
      s_heatingSystemServicedYear: (state) => state.building.services.heatingSystemServicedYear,
      s_heatingSystemServicedInspectionReport: (state) => state.building.services.heatingSystemServicedInspectionReport,
      s_foulWaterDrainage: (state) => state.building.services.foulWaterDrainage,
      s_surfaceWaterDrainage: (state) => state.building.services.surfaceWaterDrainage,
      s_sewerageSepticTank: (state) => state.building.services.sewerageSepticTank,
      s_sewerageTreatmentPlant: (state) => state.building.services.sewerageTreatmentPlant,
      s_sewerageCesspool: (state) => state.building.services.sewerageCesspool,
      s_sewerageSystemShared: (state) => state.building.services.sewerageSystemShared,
      s_sewerageSystemSharedNumber: (state) => state.building.services.sewerageSystemSharedNumber,
      s_sewerageSystemLastEmptied: (state) => state.building.services.sewerageSystemLastEmptied,
      s_sewerageSystemServicedYear: (state) => state.building.services.sewerageSystemServicedYear,
      s_sewerageSystemInstalledYear: (state) => state.building.services.sewerageSystemInstalledYear,
      s_sewerageSystemOutsideOfProperty: (state) => state.building.services.sewerageSystemOutsideOfProperty,
      s_sewerageSystemOutsideOfPropertyDocuments: (state) =>
        state.building.services.sewerageSystemOutsideOfPropertyDocuments,
    }),
    electricsTested: {
      get() {
        return {
          answer: this.s_electricsTested,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_TESTED}`](value.answer);
      },
    },
    electricsTestedYear: {
      get() {
        return {
          date: this.s_electricsTestedYear,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_TESTED_YEAR}`](value.date);
      },
    },
    electricsTestedCertificate: {
      get() {
        return {
          answer: this.s_electricsTestedCertificate,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_TESTED_CERTIFICATE}`](
          value.answer,
        );
      },
    },
    electricsRewired: {
      get() {
        return {
          answer: this.s_electricsRewired,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_REWIRED}`](value.answer);
      },
    },
    electricalSafetyCertificate: {
      get() {
        return {
          answer: this.s_electricalSafetyCertificate,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICAL_SAFETY_CERTIFICATE}`](
          value.answer,
        );
      },
    },
    complianceCertificate: {
      get() {
        return {
          answer: this.s_complianceCertificate,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_COMPLIANCE_CERTIFICATE}`](
          value.answer,
        );
      },
    },
    completionCertificate: {
      get() {
        return {
          answer: this.s_completionCertificate,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_COMPLETION_CERTIFICATE}`](
          value.answer,
        );
      },
    },
    centralHeatingSystem: {
      get() {
        return {
          answer: this.s_centralHeatingSystem,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_SYSTEM}`](
          value.answer,
        );
      },
    },
    centralHeatingSystemType: {
      get() {
        return this.s_centralHeatingSystemType;
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_SYSTEM_TYPE}`](
          value,
        );
      },
    },
    centralHeatingInstallationDate: {
      get() {
        return this.s_centralHeatingInstallationDate;
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_INSTALLATION_DATE}`](
          value,
        );
      },
    },
    septicTankReplaced: {
      get() {
        return {
          date: this.s_septicTankReplaced,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEPTIC_TANK_REPLACED}`](value.date);
      },
    },
    centralHeatingBeforeSpecifiedDate: {
      get() {
        return {
          answer: this.s_centralHeatingBeforeSpecifiedDate,
        };
      },
      set(value) {
        this[
          `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_BEFORE_SPECIFIED_DATE}`
        ](value.answer);
      },
    },
    heatingSystemInWorkingOrder: {
      get() {
        return {
          answer: this.s_heatingSystemInWorkingOrder,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_HEATING_SYSTEM_IN_WORKING_ORDER}`](
          value.answer,
        );
      },
    },
    heatingSystemServicedYear: {
      get() {
        return this.s_heatingSystemServicedYear;
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_HEATING_SYSTEM_SERVICED_YEAR}`](
          value,
        );
      },
    },
    heatingSystemServicedInspectionReport: {
      get() {
        return {
          answer: this.s_heatingSystemServicedInspectionReport,
        };
      },
      set(value) {
        this[
          `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_HEATING_SYSTEM_SERVICED_INSPECTION_REPORT}`
        ](value.answer);
      },
    },
    foulWaterDrainage: {
      get() {
        return {
          answer: this.s_foulWaterDrainage,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_FOUL_WATER_DRAINAGE}`](value.answer);
      },
    },
    surfaceWaterDrainage: {
      get() {
        return {
          answer: this.s_surfaceWaterDrainage,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SURFACE_WATER_DRAINAGE}`](
          value.answer,
        );
      },
    },
    sewerageSepticTank: {
      get() {
        return {
          answer: this.s_sewerageSepticTank,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SEPTIC_TANK}`](
          value.answer,
        );
      },
    },
    sewerageTreatmentPlant: {
      get() {
        return {
          answer: this.s_sewerageTreatmentPlant,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_TREATMENT_PLANT}`](
          value.answer,
        );
      },
    },
    sewerageCesspool: {
      get() {
        return {
          answer: this.s_sewerageCesspool,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_CESSPOOL}`](value.answer);
      },
    },
    sewerageSystemShared: {
      get() {
        return {
          answer: this.s_sewerageSystemShared,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_SHARED}`](
          value.answer,
        );
      },
    },
    sewerageSystemSharedNumber: {
      get() {
        return this.s_sewerageSystemSharedNumber;
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_SHARED_NUMBER}`](
          value,
        );
      },
    },
    sewerageSystemLastEmptied: {
      get() {
        return {
          date: this.s_sewerageSystemLastEmptied,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_LAST_EMPTIED}`](
          value.date,
        );
      },
    },
    sewerageSystemServicedYear: {
      get() {
        return {
          date: this.s_sewerageSystemServicedYear,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_SERVICED_YEAR}`](
          value.date,
        );
      },
    },
    sewerageSystemInstalledYear: {
      get() {
        return {
          date: this.s_sewerageSystemInstalledYear,
        };
      },
      set(value) {
        this[`${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_INSTALLED_YEAR}`](
          value.date,
        );
      },
    },
    sewerageSystemOutsideOfProperty: {
      get() {
        return {
          answer: this.s_sewerageSystemOutsideOfProperty,
        };
      },
      set(value) {
        this[
          `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY}`
        ](value.answer);
      },
    },
    sewerageSystemOutsideOfPropertyDocuments: {
      get() {
        return {
          answer: this.s_sewerageSystemOutsideOfPropertyDocuments,
        };
      },
      set(value) {
        this[
          `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS}`
        ](value.answer);
      },
    },
    afterSpecificDate: {
      get() {
        if (this.centralHeatingInstallationDate && this.centralHeatingInstallationDate.hasOwnProperty('date')) {
          const centralHeatingInstallationDate = new Date(this.centralHeatingInstallationDate.date);
          const cutOffDate = new Date('2005-03-31 03:00:00.000');
          return centralHeatingInstallationDate > cutOffDate;
        }
        return true;
      },
    },
  },
  data() {
    return {
      saving: false,
      centralHeatingInstallationDateNotKnown: false,
      serviceYearNotKnown: false,
      enclosedOrToFollow,
      enclosedOrToFollowNotApplicable, // This refers to the content of the top on the file
      yesOrNo,
      yesOrNoOrNotKnown,
      services,
    };
  },
  methods: {
    ...mapMutations([
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_TESTED}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_TESTED_YEAR}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_TESTED_CERTIFICATE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICS_REWIRED}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_ELECTRICAL_SAFETY_CERTIFICATE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_COMPLIANCE_CERTIFICATE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_COMPLETION_CERTIFICATE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_SYSTEM}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_SYSTEM_TYPE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_INSTALLATION_DATE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEPTIC_TANK_REPLACED}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_CENTRAL_HEATING_BEFORE_SPECIFIED_DATE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_HEATING_SYSTEM_IN_WORKING_ORDER}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_HEATING_SYSTEM_SERVICED_YEAR}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_HEATING_SYSTEM_SERVICED_INSPECTION_REPORT}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_FOUL_WATER_DRAINAGE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SURFACE_WATER_DRAINAGE}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SEPTIC_TANK}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_TREATMENT_PLANT}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_CESSPOOL}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_SHARED}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_SHARED_NUMBER}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_LAST_EMPTIED}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_SERVICED_YEAR}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_INSTALLED_YEAR}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY}`,
      `${servicesMutations.SERVICES_PREFIX}${servicesMutations.SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      if (!this.afterSpecificDate) {
        this.centralHeatingBeforeSpecifiedDate = { answer: null };
      }

      await patchServices(
        this.s_buildingId,
        this.s_electricsTested,
        this.s_electricsTestedYear,
        this.s_electricsTestedCertificate,
        this.s_electricsRewired,
        this.s_electricalSafetyCertificate,
        this.s_complianceCertificate,
        this.s_completionCertificate,
        this.s_centralHeatingSystem,
        this.s_centralHeatingSystemType,
        this.s_centralHeatingInstallationDate,
        this.s_septicTankReplaced,
        this.s_centralHeatingBeforeSpecifiedDate,
        this.s_heatingSystemInWorkingOrder,
        this.s_heatingSystemServicedYear,
        this.s_heatingSystemServicedInspectionReport,
        this.s_foulWaterDrainage,
        this.s_surfaceWaterDrainage,
        this.s_sewerageSepticTank,
        this.s_sewerageTreatmentPlant,
        this.s_sewerageCesspool,
        this.s_sewerageSystemShared,
        this.s_sewerageSystemSharedNumber,
        this.s_sewerageSystemLastEmptied,
        this.s_sewerageSystemServicedYear,
        this.s_sewerageSystemInstalledYear,
        this.s_sewerageSystemOutsideOfProperty,
        this.s_sewerageSystemOutsideOfPropertyDocuments,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
    triggerUpdate() {
      let selected = this.$refs.answer.value;
      if (selected === 'true' || selected === 'false') {
        selected = selected === 'true';
      }
      this.$emit('input', {
        answer: selected,
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Services</h2>

      <div class="col-md-12">
        <div class="section-note">
          <p>
            <b>Note:</b> If the seller does not have a certificate requested below this can be obtained from the
            relevant Competent Persons Scheme. Further information about Competent Persons Schemes can be found at:
            <a href="http://www.gov.uk" target="_blank">www.gov.uk</a>.
          </p>
        </div>
      </div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <div class="section-title">
            <h3>Electricity</h3>
          </div>
        </div>

        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="electricsTestedLabelName"
            title="Has the whole or any part of the electrical installation been tested by a qualified and registered electrician?"
            v-model="electricsTested"
            validationFieldName="electrics tested"
          ></simple-question>
        </div>
        <div v-if="electricsTested.answer" class="col-md-12">
          <h5><label>If Yes:</label></h5>
          <free-type-year-question
            title="Please state the year electrical installation was tested"
            labelName="electricsTestedYearLabelName"
            v-model="electricsTestedYear"
            :required="true"
            validationFieldName="electrics tested year"
          />
          <simple-question
            :dropdownOptions="enclosedOrToFollow"
            labelName="electricsTestedCertificateLabelName"
            title="Please provide a copy of the test certificate."
            v-model="electricsTestedCertificate"
            validationFieldName="electrics tested certificate"
          />
          <inline-file-uploader
            v-if="electricsTestedCertificate.answer === 'ETF_ENCLOSED'"
            :requiredDocument="services.electricsTestedCertificate"
          />
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="electricsRewiredLabelName"
            title="Has the property been rewired or had any electrical installation work carried out since 1 January 2005?"
            v-model="electricsRewired"
            validationFieldName="electrics rewired"
          />
        </div>

        <div v-if="electricsRewired.answer === 'YNNK_YES'" class="col-md-12">
          <h5><label>If Yes, please supply one of the following:</label></h5>
          <simple-question
            titleNumberOrLetter="(a)"
            :dropdownOptions="enclosedOrToFollowNotApplicable"
            labelName="electricalSafetyCertificateLabelName"
            title="a copy of the signed BS7671 Electrical Safety Certificate"
            v-model="electricalSafetyCertificate"
            validationFieldName="electrics safety certificate"
          />

          <inline-file-uploader
            v-if="electricalSafetyCertificate.answer === 'ETFNA_ENCLOSED'"
            :requiredDocument="services.electricalSafetyCertificate"
          />

          <simple-question
            titleNumberOrLetter="(b)"
            :dropdownOptions="enclosedOrToFollowNotApplicable"
            labelName="complianceCertificateLabelName"
            title="the installer’s Building Regulations Compliance Certificate"
            v-model="complianceCertificate"
            validationFieldName="compliance certificate"
          />

          <inline-file-uploader
            v-if="complianceCertificate.answer === 'ETFNA_ENCLOSED'"
            :requiredDocument="services.complianceCertificate"
          />

          <simple-question
            titleNumberOrLetter="(c)"
            :dropdownOptions="enclosedOrToFollowNotApplicable"
            labelName="completionCertificateLabelName"
            title="the Building Control Completion Certificate"
            v-model="completionCertificate"
            validationFieldName="building control completion certificate"
          />

          <inline-file-uploader
            v-if="completionCertificate.answer === 'ETFNA_ENCLOSED'"
            :requiredDocument="services.completionCertificate"
          />
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <div class="section-title">
            <h3>Central heating</h3>
          </div>
        </div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="centralHeatingSystemLabelName"
            title="Does the property have a central heating system?"
            v-model="centralHeatingSystem"
            validationFieldName="central heating system"
          ></simple-question>
        </div>

        <div v-if="centralHeatingSystem.answer">
          <div class="col-md-12">
            <h5><label>If Yes:</label></h5>
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-1">
                    <h5>(a)</h5>
                  </div>
                  <div class="col-md-11">
                    <h5>
                      <label class="mandatory"
                        >What type of system is it (e.g. mains gas, liquid gas, oil, electricity, etc.)?</label
                      >
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <ValidationProvider
                  name="central heating system type"
                  rules="required|min:3"
                  v-slot="validationContext"
                >
                  <label for="heatingType"></label>
                  <b-form-input
                    v-model="centralHeatingSystemType"
                    id="heatingType"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback data-error-name="text-error"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <free-type-year-question
              disabled="centralHeatingInstallationDate"
              title="When was the heating system installed?"
              titleNumberOrLetter="(b)"
              labelName="centralHeatingInstallationDateLabelName"
              labelText="Date"
              v-model="centralHeatingInstallationDate"
              validationFieldName="central heating installation date"
              :required="true"
              :not-known="true"
              :minimumView="'day'"
            ></free-type-year-question>
          </div>

          <div class="col-md-12">
            <simple-question
              v-if="afterSpecificDate"
              :dropdownOptions="enclosedOrToFollow"
              labelName="centralHeatingBeforeSpecifiedDateLabelName"
              titleNumberOrLetter=" "
              title="Please supply a copy of the ‘completion
                                  certificate’ (e.g. CORGI or Gas Safe Register) or the
                                  ‘exceptional circumstances’ form."
              v-model="centralHeatingBeforeSpecifiedDate"
              validationFieldName="central heating system before specified date"
            ></simple-question>
            <inline-file-uploader
              v-if="centralHeatingBeforeSpecifiedDate.answer === 'ETF_ENCLOSED'"
              :requiredDocument="services.centralHeatingBeforeSpecifiedDate"
            />
          </div>

          <div class="col-md-12">
            <simple-question
              :dropdownOptions="yesOrNo"
              labelName="heatingSystemInWorkingOrderLabelName"
              titleNumberOrLetter="(c)"
              title="Is the heating system in good working order?"
              v-model="heatingSystemInWorkingOrder"
              validationFieldName="central system in working order"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <free-type-year-question
              title="In what year was the heating system last serviced/maintained?"
              titleNumberOrLetter="(d)"
              labelName="heatingSystemServicedYearLabelName"
              v-model="heatingSystemServicedYear"
              validationFieldName="heating system serviced year"
              :required="true"
              :not-known="true"
            ></free-type-year-question>

            <simple-question
              v-if="heatingSystemServicedYear !== 'not known' && heatingSystemServicedYear !== null"
              :dropdownOptions="enclosedOrToFollowNotApplicable"
              labelName="heatingSystemServicedInspectionReportLabelName"
              titleNumberOrLetter="(e)"
              title="Please supply a copy of the inspection report."
              v-model="heatingSystemServicedInspectionReport"
              validationFieldName="heating system service inspection report"
            ></simple-question>
            <inline-file-uploader
              v-if="heatingSystemServicedInspectionReport.answer === 'ETFNA_ENCLOSED'"
              :requiredDocument="services.heatingSystemServicedInspectionReport"
            />
          </div>
        </div>
        <!-- /question 3 end -->

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <div class="section-title">
            <h3>Drainage and sewerage</h3>
          </div>

          <div class="section-note">
            <p>
              <b>Note:</b> Further information about drainage and sewerage can be found at:
              <a href="http://www.gov.uk/government/organisations/environment-agency" target="_blank"
                >www.gov.uk/government/organisations/environment-agency</a
              >
            </p>
          </div>
        </div>

        <div class="border-top my-3"></div>

        <div class="col-md-12">
          <h5>Is the property connected to mains:</h5>

          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            titleNumberOrLetter="(a)"
            labelName="foulWaterDrainageLabelName"
            title="foul water drainage?"
            v-model="foulWaterDrainage"
            validationFieldName="foul water drainage"
          ></simple-question>

          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            titleNumberOrLetter="(b)"
            labelName="surfaceWaterDrainageLabelName"
            title="surface water drainage?"
            v-model="surfaceWaterDrainage"
            validationFieldName="surface water drainage"
          ></simple-question>
        </div>
        <!-- /question 4 end -->

        <!-- If Yes for both above, skip section bellow and jump to another section -->
        <div
          v-if="
            foulWaterDrainage.answer === 'YNNK_NO' ||
            foulWaterDrainage.answer === 'YNNK_NOT_KNOWN' ||
            surfaceWaterDrainage.answer === 'YNNK_NO' ||
            surfaceWaterDrainage.answer === 'YNNK_NOT_KNOWN'
          "
        >
          <div class="border-top my-3"></div>

          <!-- Question 5 -->
          <div class="col-md-12">
            <h5>Is sewerage for the property provided by:</h5>

            <simple-question
              :dropdownOptions="yesOrNo"
              titleNumberOrLetter="(a)"
              labelName="sewerageSepticTankLabelName"
              title="a septic tank?"
              v-model="sewerageSepticTank"
              validationFieldName="sewerage septic tank"
            ></simple-question>

            <div v-if="s_sewerageSepticTank">
              <p>
                <b
                  >If your septic tank discharges directly into surface water, you must have upgraded or replaced this
                  by January 2020.</b
                >
              </p>

              <free-type-year-question
                title="When was the septic tank last replaced or upgraded?"
                labelName="septicTankReplacedLabelName"
                v-model="septicTankReplaced"
                validationFieldName="septic tank last replaced or upgraded date"
                :required="true"
                :minimumView="'day'"
              ></free-type-year-question>
            </div>

            <simple-question
              :dropdownOptions="yesOrNo"
              titleNumberOrLetter="(b)"
              labelName="sewerageTreatmentPlantLabelName"
              title="a sewage treatment plant?"
              v-model="sewerageTreatmentPlant"
              validationFieldName="sewerage treatment plant"
            ></simple-question>

            <simple-question
              :dropdownOptions="yesOrNo"
              titleNumberOrLetter="(c)"
              labelName="sewerageCesspoolLabelName"
              title="cesspool?"
              v-model="sewerageCesspool"
              validationFieldName="sewerage cesspool"
            ></simple-question>
          </div>
          <!-- /question 5 end -->

          <div class="border-top my-3"></div>

          <!-- Sewerage section - Display only when at list one of the fallowing sewerage option is provided: septic tank, sewage treatment plant, cesspool -->
          <div
            v-if="
              sewerageSepticTank.answer === true ||
              sewerageTreatmentPlant.answer === true ||
              sewerageCesspool.answer === true
            "
          >
            <!-- Question 6 -->
            <div class="col-md-12">
              <simple-question
                :dropdownOptions="yesOrNo"
                labelName="sewerageSystemSharedLabelName"
                title="Is the use of the septic tank, sewage treatment plant or cesspool shared with other properties?"
                v-model="sewerageSystemShared"
                validationFieldName="sewerage system shared"
              ></simple-question>

              <div v-if="sewerageSystemShared.answer">
                <div class="row">
                  <div class="col-md-9">
                    <h5>If Yes, how many properties share the system?</h5>
                  </div>
                  <div class="col-md-3">
                    <ValidationProvider
                      name="sewerage system shared number"
                      rules="required|min_value:1"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        v-model="sewerageSystemSharedNumber"
                        id="sewerageSystemSharedNumber"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback data-error-name="text-error"
                        >{{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <!-- /question 6 end -->

            <div class="border-top my-3"></div>

            <!-- Question 7 -->
            <div class="col-md-12">
              <free-type-year-question
                title="When was the system last emptied?"
                labelName="sewerageSystemLastEmptiedLabelName"
                v-model="sewerageSystemLastEmptied"
                :required="true"
                validationFieldName="sewerage system last emptied"
              ></free-type-year-question>
            </div>
            <!-- /question 7 end -->

            <div class="border-top my-3"></div>

            <!-- Show this section only when served by a sewage treatment plant -->
            <div v-if="sewerageTreatmentPlant.answer === true">
              <!-- Question 8 -->
              <div class="col-md-12">
                <free-type-year-question
                  title="If the property is served by a sewage treatment plant, when was the treatment plant
                            last serviced?"
                  labelName="sewerageSystemServicedYearLabelName"
                  v-model="sewerageSystemServicedYear"
                  :required="true"
                  validationFieldName="sewerage system serviced year"
                ></free-type-year-question>
              </div>
              <!-- /question 8 end -->
              <div class="border-top my-3"></div>
            </div>
            <!-- End section served by a sewage treatment plant-->

            <!-- Question 9 -->
            <div class="col-md-12">
              <free-type-year-question
                title="When was the system installed?"
                labelName="sewerageSystemInstalledYearLabelName"
                v-model="sewerageSystemInstalledYear"
                :required="true"
                validationFieldName="sewerage system installed"
              ></free-type-year-question>
            </div>
            <!-- /question 9 end -->

            <div class="border-top my-3"></div>

            <!-- Note Info -->
            <div class="col-md-12">
              <div class="section-note">
                <p>
                  <b>Note:</b> Some systems installed after 1 January 1991 require Building Regulations approval,
                  environmental permits or registration. Further information about permits and registration can be found
                  at:
                  <a href="http://www.gov.uk/government/organisations/environment-agency" target="_blank"
                    >www.gov.uk/government/organisations/environment-agency</a
                  >.
                </p>
              </div>
            </div>
            <!-- /note info -->

            <div class="border-top my-3"></div>

            <!-- Question 10 -->
            <div class="col-md-12">
              <simple-question
                :dropdownOptions="yesOrNo"
                labelName="sewerageSystemOutsideOfPropertyLabelName"
                title="Is any part of the septic tank, sewage treatment plant
                                        (including any soakaway or outfall) or cesspool, or the
                                        access to it, outside the boundary of the property?"
                v-model="sewerageSystemOutsideOfProperty"
                validationFieldName="sewerage system outside of property"
              ></simple-question>

              <div v-if="sewerageSystemOutsideOfProperty.answer">
                <simple-question
                  :dropdownOptions="enclosedOrToFollow"
                  labelName="sewerageSystemOutsideOfPropertyDocumentsLabelName"
                  title="Please supply a plan showing the location of the
                                                system and how access is obtained."
                  v-model="sewerageSystemOutsideOfPropertyDocuments"
                  validationFieldName="sewerage system outside of property documents"
                ></simple-question>
                <inline-file-uploader
                  v-if="sewerageSystemOutsideOfPropertyDocuments.answer === 'ETF_ENCLOSED'"
                  :requiredDocument="services.sewerageSystemOutsideOfPropertyDocuments"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="section-note">
                <p>
                  Specific information about permits and general binding rules can be found at
                  <a href="http://www.gov.uk/permits-you-need-for-septic-tanks" target="_blank"
                    >www.gov.uk/permits-you-need-for-septic-tanks</a
                  >.
                </p>
              </div>
            </div>
            <!-- /question 10 end -->
          </div>
          <!-- End sewerage section -->
        </div>
        <!-- /If Yes for both above, skip section bellow and jump to another section -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>

<style scoped>
.row {
  padding: 5px 0;
}
</style>
