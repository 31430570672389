import axios from 'axios';

export default (id, arrangementsDescription, controlledParkingZone) => {
  const preparedData = {
    arrangements_description: arrangementsDescription,
    controlled_parking_zone: controlledParkingZone,
  };

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    parking: preparedData,
  });
};
