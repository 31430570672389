<script>
import { mapMutations, mapState } from 'vuex';
import * as disputesMutations from '@/store/notices/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import patchNotices from '@/api/buildings/patchNotices';

export default {
  components: { OpenQuestion },
  comments: [OpenQuestion],
  name: 'Notices',
  computed: {
    ...mapState({
      s_noticesReceived: (state) => state.building.notices.noticesReceived,
      s_noticesReceivedDescription: (state) => state.building.notices.noticesReceivedDescription,
      s_developmentProposals: (state) => state.building.notices.developmentProposals,
      s_developmentProposalsDescription: (state) => state.building.notices.developmentProposalsDescription,
      s_buildingId: (state) => state.buildingId,
    }),
    noticesReceived: {
      get() {
        return {
          answer: this.s_noticesReceived,
          description: this.s_noticesReceivedDescription,
        };
      },
      set(value) {
        this[`${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_RECEIVED}`](value.answer);
        this[`${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_RECEIVED_DESCRIPTION}`](
          value.description,
        );
      },
    },
    developmentProposals: {
      get() {
        return {
          answer: this.s_developmentProposals,
          description: this.s_developmentProposalsDescription,
        };
      },
      set(value) {
        this[`${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_DEVELOPMENT_PROPOSALS}`](value.answer);
        this[`${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_DEVELOPMENT_PROPOSALS_DESCRIPTION}`](
          value.description,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    ...mapMutations([
      `${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_RECEIVED}`,
      `${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_RECEIVED_DESCRIPTION}`,
      `${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_DEVELOPMENT_PROPOSALS}`,
      `${disputesMutations.NOTICES_PREFIX}${disputesMutations.NOTICE_SET_DEVELOPMENT_PROPOSALS_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchNotices(
        this.s_buildingId,
        this.s_noticesReceived,
        this.s_noticesReceivedDescription,
        this.s_developmentProposals,
        this.s_developmentProposalsDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Notices and proposals</h2>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <open-question
            detailsName="notices received details"
            dropdownName="noticesReceivedDropdownName"
            labelName="noticesReceivedLabelName"
            textAreaName="noticesReceivedTextAreaName"
            title="Have any notices or correspondence been received or sent
(e.g. from or to a neighbour, council or government
department), or any negotiations or discussions taken
place, which affect the property or a property nearby?"
            descriptionTitle="If Yes, please give details:"
            v-model="noticesReceived"
            validationFieldName="notices received"
          ></open-question>
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <open-question
            dropdownName="developmentProposalsDropdownName"
            labelName="developmentProposalsLabelName"
            textAreaName="developmentProposalsTextAreaName"
            detailsName="development proposals details"
            title="Is the seller aware of any proposals to develop property or
land nearby, or of any proposals to make alterations to
buildings nearby?"
            descriptionTitle="If Yes, please give details:"
            v-model="developmentProposals"
            validationFieldName="development proposals"
          ></open-question>
        </div>
        <!-- /question 2 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
