<script>
import { mapMutations, mapState } from 'vuex';
import * as alterationsMutations from '@/store/alterations/mutations';
import FreeTypeYearQuestion from '@/components/forms/inputGroups/FreeTypeYearQuestion';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchAlterations from '@/api/buildings/patchAlterations';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import alterations from '@/functions/supportingDocuments/alterations';

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnown = {
  YNNK_YES: 'Yes',
  YNNK_NO: 'No',
  YNNK_NOT_KNOWN: 'Not Known',
};

export default {
  name: 'Alterations',
  components: { SimpleQuestion, FreeTypeYearQuestion, OpenQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_buildingWorks: (state) => state.building.alterations.buildingWorks,
      s_buildingWorksDescription: (state) => state.building.alterations.buildingWorksDescription,
      s_changeOfUse: (state) => state.building.alterations.changeOfUse,
      s_changeOfUseYear: (state) => state.building.alterations.changeOfUseYear,
      s_replacementWindowInstallation: (state) => state.building.alterations.replacementWindowInstallation,
      s_replacementWindowInstallationYear: (state) => state.building.alterations.replacementWindowInstallationYear,
      s_conservatoryAddition: (state) => state.building.alterations.conservatoryAddition,
      s_conservatoryAdditionYear: (state) => state.building.alterations.conservatoryAdditionYear,
      s_planningPermissionNotRequiredDescription: (state) =>
        state.building.alterations.planningPermissionNotRequiredDescription,
      s_changesUnfinished: (state) => state.building.alterations.changesUnfinished,
      s_changesUnfinishedDescription: (state) => state.building.alterations.changesUnfinishedDescription,
      s_planningPermissionConditionsBreached: (state) =>
        state.building.alterations.planningPermissionConditionsBreached,
      s_planningPermissionConditionsBreachedDescription: (state) =>
        state.building.alterations.planningPermissionConditionsBreachedDescription,
      s_buildingControlIssuesToResolve: (state) => state.building.alterations.buildingControlIssuesToResolve,
      s_buildingControlIssuesToResolveDescription: (state) =>
        state.building.alterations.buildingControlIssuesToResolveDescription,
      s_solarPanelsInstalled: (state) => state.building.alterations.solarPanelsInstalled,
      s_solarPanelsInstalledYear: (state) => state.building.alterations.solarPanelsInstalledYear,
      s_solarPanelsOwned: (state) => state.building.alterations.solarPanelsOwned,
      s_solarPanelsLongLease: (state) => state.building.alterations.solarPanelsLongLease,
      s_solarPanelsLongLeaseDocuments: (state) => state.building.alterations.solarPanelsLongLeaseDocuments,
      s_listedBuilding: (state) => state.building.alterations.listedBuilding,
      s_conservationArea: (state) => state.building.alterations.conservationArea,
      s_listedBuildingConservationAreaDocuments: (state) =>
        state.building.alterations.listedBuildingConservationAreaDocuments,
      s_treesSubjectToPreservationOrder: (state) => state.building.alterations.treesSubjectToPreservationOrder,
      s_preservationOrderCompliedWith: (state) => state.building.alterations.preservationOrderCompliedWith,
      s_preservationOrderRelevantDocuments: (state) => state.building.alterations.preservationOrderRelevantDocuments,
      s_buildingId: (state) => state.buildingId,
    }),
    buildingWorks: {
      get() {
        return {
          answer: this.s_buildingWorks,
          description: this.s_buildingWorksDescription,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_WORKS}`](
          value.answer,
        );
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_WORKS_DESCRIPTION}`
        ](value.description);
      },
    },
    changeOfUse: {
      get() {
        return {
          answer: this.s_changeOfUse,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGE_OF_USE}`](
          value.answer,
        );
      },
    },
    changeOfUseYear: {
      get() {
        return {
          date: this.s_changeOfUseYear,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGE_OF_USE_YEAR}`](
          value.date,
        );
      },
    },
    replacementWindowInstallation: {
      get() {
        return {
          answer: this.s_replacementWindowInstallation,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION}`
        ](value.answer);
      },
    },
    replacementWindowInstallationYear: {
      get() {
        return {
          date: this.s_replacementWindowInstallationYear,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION_YEAR}`
        ](value.date);
      },
    },
    conservatoryAddition: {
      get() {
        return {
          answer: this.s_conservatoryAddition,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CONSERVATORY_ADDITION}`](
          value.answer,
        );
      },
    },
    conservatoryAdditionYear: {
      get() {
        return {
          date: this.s_conservatoryAdditionYear,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CONSERVATORY_ADDITION_YEAR}`
        ](value.date);
      },
    },
    planningPermissionNotRequiredDescription: {
      get() {
        return this.s_planningPermissionNotRequiredDescription;
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PLANNING_PERMISSION_NOT_REQUIRED_DESCRIPTION}`
        ](value);
      },
    },
    changesUnfinished: {
      get() {
        return {
          answer: this.s_changesUnfinished,
          description: this.s_changesUnfinishedDescription,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGES_UNFINISHED}`](
          value.answer,
        );
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGES_UNFINISHED_DESCRIPTION}`
        ](value.description);
      },
    },
    planningPermissionConditionsBreached: {
      get() {
        return {
          answer: this.s_planningPermissionConditionsBreached,
          description: this.s_planningPermissionConditionsBreachedDescription,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED}`
        ](value.answer);
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED_DESCRIPTION}`
        ](value.description);
      },
    },
    buildingControlIssuesToResolve: {
      get() {
        return {
          answer: this.s_buildingControlIssuesToResolve,
          description: this.s_buildingControlIssuesToResolveDescription,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE}`
        ](value.answer);
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE_DESCRIPTION}`
        ](value.description);
      },
    },
    solarPanelsInstalled: {
      get() {
        return {
          answer: this.s_solarPanelsInstalled,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_INSTALLED}`
        ](value.answer);
      },
    },
    solarPanelsInstalledYear: {
      get() {
        return {
          date: this.s_solarPanelsInstalledYear,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_INSTALLED_YEAR}`
        ](value.date);
      },
    },
    solarPanelsOwned: {
      get() {
        return {
          answer: this.s_solarPanelsOwned,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_OWNED}`](
          value.answer,
        );
      },
    },
    solarPanelsLongLease: {
      get() {
        return {
          answer: this.s_solarPanelsLongLease,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE}`
        ](value.answer);
      },
    },
    solarPanelsLongLeaseDocuments: {
      get() {
        return {
          answer: this.s_solarPanelsLongLeaseDocuments,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE_DOCUMENTS}`
        ](value.answer);
      },
    },
    listedBuilding: {
      get() {
        return {
          answer: this.s_listedBuilding,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_LISTED_BUILDING}`](
          value.answer,
        );
      },
    },
    conservationArea: {
      get() {
        return {
          answer: this.s_conservationArea,
        };
      },
      set(value) {
        this[`${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CONSERVATION_AREA}`](
          value.answer,
        );
      },
    },
    listedBuildingConservationAreaDocuments: {
      get() {
        return {
          answer: this.s_listedBuildingConservationAreaDocuments,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS}`
        ](value.answer);
      },
    },
    treesSubjectToPreservationOrder: {
      get() {
        return {
          answer: this.s_treesSubjectToPreservationOrder,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_TREES_SUBJECT_TO_PRESERVATION_ORDER}`
        ](value.answer);
      },
    },
    preservationOrderCompliedWith: {
      get() {
        return {
          answer: this.s_preservationOrderCompliedWith,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PRESERVATION_ORDER_COMPLIED_WITH}`
        ](value.answer);
      },
    },
    preservationOrderRelevantDocuments: {
      get() {
        return {
          answer: this.s_preservationOrderRelevantDocuments,
        };
      },
      set(value) {
        this[
          `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PRESERVATION_ORDER_RELEVANT_DOCUMENTS}`
        ](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      enclosedOrToFollow, // This refers to the content of the top on the file
      yesOrNo,
      yesOrNoOrNotKnown,
      alterations,
    };
  },
  methods: {
    ...mapMutations([
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_WORKS}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_WORKS_DESCRIPTION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGE_OF_USE}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGE_OF_USE_YEAR}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION_YEAR}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CONSERVATORY_ADDITION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CONSERVATORY_ADDITION_YEAR}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PLANNING_PERMISSION_NOT_REQUIRED_DESCRIPTION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGES_UNFINISHED}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CHANGES_UNFINISHED_DESCRIPTION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED_DESCRIPTION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE_DESCRIPTION}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_INSTALLED}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_INSTALLED_YEAR}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_OWNED}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE_DOCUMENTS}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_LISTED_BUILDING}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_CONSERVATION_AREA}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_TREES_SUBJECT_TO_PRESERVATION_ORDER}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PRESERVATION_ORDER_COMPLIED_WITH}`,
      `${alterationsMutations.ALTERATIONS_PREFIX}${alterationsMutations.ALTERATIONS_SET_PRESERVATION_ORDER_RELEVANT_DOCUMENTS}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchAlterations(
        this.s_buildingId,
        this.s_buildingWorks,
        this.s_buildingWorksDescription,
        this.s_changeOfUse,
        this.s_changeOfUseYear,
        this.s_replacementWindowInstallation,
        this.s_replacementWindowInstallationYear,
        this.s_conservatoryAddition,
        this.s_conservatoryAdditionYear,
        this.s_planningPermissionNotRequiredDescription,
        this.s_changesUnfinished,
        this.s_changesUnfinishedDescription,
        this.s_planningPermissionConditionsBreached,
        this.s_planningPermissionConditionsBreachedDescription,
        this.s_buildingControlIssuesToResolve,
        this.s_buildingControlIssuesToResolveDescription,
        this.s_solarPanelsInstalled,
        this.s_solarPanelsInstalledYear,
        this.s_solarPanelsOwned,
        this.s_solarPanelsLongLease,
        this.s_solarPanelsLongLeaseDocuments,
        this.s_listedBuilding,
        this.s_conservationArea,
        this.s_listedBuildingConservationAreaDocuments,
        this.s_treesSubjectToPreservationOrder,
        this.s_preservationOrderCompliedWith,
        this.s_preservationOrderRelevantDocuments,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Alterations, planning and building control</h2>

      <div class="col-md-12">
        <div class="section-note">
          <p>
            <b>Note to seller:</b> All relevant approvals and supporting paperwork referred to in section 4 of this
            form, such as listed building consents, planning permissions, Building Regulations consents and completion
            certificates should be provided. If the seller has had works carried out the seller should produce the
            documentation authorising this. Copies may be obtained from the relevant local authority website. Competent
            Persons Certificates may be obtained from the contractor or the scheme provider (e.g. FENSA or Gas Safe
            Register). Further information about Competent Persons Certificates can be found at:
            <a
              href="https://www.gov.uk/guidance/competent-person-scheme-current-schemes-and-how-schemes-are-authorised"
              target="_blank"
            >
              https://www.gov.uk/guidance/competent-person-scheme-current-schemes-and-how-schemes-are-authorised</a
            >
          </p>
        </div>
      </div>

      <div class="col-md-12 my-3">
        <div class="section-note">
          <p>
            <b>Note to buyer:</b> If any alterations or improvements have been made since the property was last valued
            for council tax, the sale of the property may trigger a revaluation. This may mean that following completion
            of the sale, the property will be put into a higher council tax band. Further information about council tax
            valuation can be found at:
            <a href="http://www.gov.uk/government/organisations/valuation-office-agency" target="_blank">
              http://www.gov.uk/government/organisations/valuation-office-agency
            </a>
          </p>
        </div>
      </div>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <h5>
            <label
              >Have any of the following changes been made to the whole or any part of the property (including the
              garden)?</label
            >
          </h5>
        </div>

        <div class="col-md-12">
          <open-question
            additionalDropdownName="buildingWorksAdditionalDropdownName"
            dropdownName="buildingWorksDropdownName"
            detailsName="building works details"
            labelName="buildingWorksLabelName"
            textAreaName="buildingWorksTextAreaName"
            titleNumberOrLetter="(a)"
            title="Building works (eg extension, loft or garage conversion, removal of internal walls)."
            descriptionTitle="If Yes, please give details including dates of all work undertaken:"
            v-model="buildingWorks"
            validationFieldName="building works"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="changeOfUseLabelName"
            titleNumberOrLetter="(b)"
            title="Change of use (eg from an office to a residence)"
            v-model="changeOfUse"
            validationFieldName="change of use"
          ></simple-question>
        </div>

        <div v-if="this.s_changeOfUse" class="col-md-12">
          <free-type-year-question
            labelName="changeOfUseYearLabelName"
            v-model="changeOfUseYear"
            :required="true"
            validationFieldName="change of use year"
          ></free-type-year-question>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="replacementWindowInstallationLabelName"
            titleNumberOrLetter="(c)"
            title="Installation of replacement windows, roof windows, roof lights, glazed doors since 1 April 2002"
            v-model="replacementWindowInstallation"
            validationFieldName="replacement window installation"
          ></simple-question>
        </div>

        <div v-if="this.s_replacementWindowInstallation" class="col-md-12">
          <free-type-year-question
            labelName="replacementWindowInstallationYearLabelName"
            v-model="replacementWindowInstallationYear"
            :required="true"
            validationFieldName="replacement window installation year"
          ></free-type-year-question>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="conservatoryAdditionLabelName"
            titleNumberOrLetter="(d)"
            title="Addition of a conservatory"
            v-model="conservatoryAddition"
            validationFieldName="conservatory addition"
          ></simple-question>
        </div>

        <div v-if="this.s_conservatoryAddition" class="col-md-12">
          <free-type-year-question
            labelName="conservatoryAdditionYearLabelName"
            v-model="conservatoryAdditionYear"
            :required="true"
            validationFieldName="conservatory addition year"
          ></free-type-year-question>
        </div>
        <!-- /question 1 end -->

        <!-- If yes for any, ask additional questions-->
        <div
          v-if="
            this.s_buildingWorks ||
            this.s_changeOfUse ||
            this.s_replacementWindowInstallation ||
            this.s_conservatoryAddition
          "
        >
          <!-- Question 2 -->
          <div class="border-top my-3"></div>
          <div class="col-md-12">
            <h5>
              <label
                >If Yes to any of the questions above and if the work was undertaken during the seller’s ownership of
                the property:</label
              >
            </h5>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-1">
                <h5><label>(a)</label></h5>
              </div>
              <div class="col-md-11">
                <h5>
                  <label
                    >please supply copies of the planning permissions, Building Regulations approvals and Completion
                    Certificates, OR:</label
                  >
                </h5>
              </div>
            </div>
          </div>

          <inline-file-uploader :requiredDocument="alterations.certificates" />

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-1">
                <h5><label for="planning-permissions-not-required-description">(b)</label></h5>
              </div>
              <div class="col-md-11">
                <h5>
                  <label for="planning-permissions-not-required-description"
                    >if none were required, please explain why these were not required – e.g. permitted development
                    rights applied or the work was exempt from Building Regulations:</label
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <validation-provider
              name="planning permission not required description"
              rules="min:3"
              v-slot="validationContext"
            >
              <b-form-textarea
                v-model="planningPermissionNotRequiredDescription"
                id="planning-permissions-not-required-description"
                rows="4"
                :state="getValidationState(validationContext)"
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="col-md-12 mt-3">
            <div class="section-note">
              <p>
                For further information about permitted development go to:
                <a href="https://www.planningportal.co.uk/info/200126/applications" target="_blank"
                  >https://www.planningportal.co.uk/info/200126/applications</a
                >
              </p>
            </div>
          </div>
          <!-- /question 2 end -->

          <!-- Question 3 -->
          <div class="border-top my-3"></div>
          <div class="col-md-12">
            <open-question
              v-model="changesUnfinished"
              title="Are any of the works disclosed in first question unfinished?"
              descriptionTitle="If Yes, please give details:"
              validationFieldName="changes unfinished"
              detailsName="changes unfinished details"
              labelName="changesUnfinishedLabelName"
              dropdownName="changesUnfinishedDropdownName"
              textAreaName="changesUnfinishedTextAreaName"
            ></open-question>
          </div>
          <!-- question 3 end -->
        </div>

        <!-- Question 4 -->
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <open-question
            v-model="planningPermissionConditionsBreached"
            title="Is the seller aware of any breach of planning permission
                        conditions or Building Regulations consent conditions,
                        unfinished work or work that does not have all necessary
                        consents?"
            descriptionTitle="If Yes, please give details:"
            detailsName="planning permission conditions breached details"
            validationFieldName="planning permission conditions breached"
            labelName="planningPermissionConditionsBreachedLabelName"
            dropdownName="planningPermissionConditionsBreachedDropdownName"
            textAreaName="planningPermissionConditionsBreachedTextAreaName"
          ></open-question>
        </div>
        <!-- /question 4 end -->

        <!-- Question 5 -->
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <open-question
            v-model="buildingControlIssuesToResolve"
            title="Are there any planning or building control issues to resolve?"
            descriptionTitle="If Yes, please give details:"
            validationFieldName="building control issues to resolve"
            detailsName="building control issues to resolve details"
            labelName="buildingControlIssuesToResolveLabelName"
            dropdownName="buildingControlIssuesToResolveDropdownName"
            textAreaName="buildingControlIssuesToResolveTextAreaName"
          ></open-question>
        </div>
        <!-- /question 5 end -->

        <!-- Question 6 -->
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <simple-question
            v-model="solarPanelsInstalled"
            :dropdownOptions="yesOrNo"
            labelName="solarPanelsInstalledLabelName"
            title="Have solar panels been installed?"
            validationFieldName="solar panels installed"
          ></simple-question>
        </div>

        <!-- If solar panels installed, ask additional questions-->
        <div v-if="this.s_solarPanelsInstalled">
          <div class="col-md-12">
            <h5><label>If Yes:</label></h5>
          </div>
          <div class="col-md-12">
            <free-type-year-question
              labelName="solarPanelsInstalledYearLabelName"
              v-model="solarPanelsInstalledYear"
              titleNumberOrLetter="(a)"
              title="In what year were the solar panels installed?"
              :required="true"
              validationFieldName="solar panels installed year"
            ></free-type-year-question>
          </div>

          <div class="col-md-12">
            <simple-question
              :dropdownOptions="yesOrNo"
              labelName="solarPanelsOwnedLabelName"
              titleNumberOrLetter="(b)"
              title="Are the solar panels owned outright?"
              v-model="solarPanelsOwned"
              validationFieldName="solar panels owned"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <simple-question
              :dropdownOptions="yesOrNo"
              labelName="solarPanelsLongLease"
              titleNumberOrLetter="(c)"
              title="Has a long lease of the roof/air space been granted to
                            a solar panel provider?"
              v-model="solarPanelsLongLease"
              validationFieldName="solar panels long lease"
            ></simple-question>
          </div>

          <div v-if="this.s_solarPanelsLongLease" class="col-md-12">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="solarPanelsLongLeaseDocumentsLabelName"
              title="If Yes, please supply copies of the relevant documents e.g. copies of electricity
                                 bills for feed in tariffs."
              v-model="solarPanelsLongLeaseDocuments"
              validationFieldName="solar panels long lease documents"
            ></simple-question>
            <inline-file-uploader
              v-if="solarPanelsLongLeaseDocuments.answer === 'ETF_ENCLOSED'"
              :requiredDocument="alterations.solarPanelsLongLeaseDocuments"
            />
          </div>
        </div>
        <!-- /question 6 End -->

        <!-- Question 7 -->
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <h5><label>Is the property or any part of it:</label></h5>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="listedBuildingLabelName"
            titleNumberOrLetter="(a)"
            title="A listed building?"
            v-model="listedBuilding"
            validationFieldName="listed building"
          ></simple-question>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="conservationAreaLabelName"
            titleNumberOrLetter="(b)"
            title="In a conservation area?"
            v-model="conservationArea"
            validationFieldName="conservation area"
          ></simple-question>
        </div>

        <div v-if="conservationArea.answer === 'YNNK_YES' || listedBuilding.answer === 'YNNK_YES'" class="col-md-12">
          <simple-question
            :dropdownOptions="enclosedOrToFollow"
            labelName="conversationAreaDocumentsLabelName"
            title="If Yes, please supply copies of any relevant documents"
            validationFieldName="conservation area documents"
            v-model="listedBuildingConservationAreaDocuments"
          ></simple-question>
          <inline-file-uploader
            v-if="listedBuildingConservationAreaDocuments.answer === 'ETF_ENCLOSED'"
            :requiredDocument="alterations.listedBuildingConservationAreaDocuments"
          />
        </div>
        <!-- /question 7 end -->

        <!-- Question 8 -->
        <div class="border-top my-3"></div>
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="treesSubjectToPreservationOrderLabelName"
            title="Are any of the trees on the property subject to a Tree Preservation Order?"
            v-model="treesSubjectToPreservationOrder"
            validationFieldName="trees subject to preservation order"
          ></simple-question>
        </div>

        <div v-if="this.s_treesSubjectToPreservationOrder === 'YNNK_YES'">
          <div class="col-md-12">
            <h5><label>If Yes:</label></h5>
          </div>

          <div class="col-md-12">
            <simple-question
              :dropdownOptions="yesOrNoOrNotKnown"
              labelName="preservationOrderCompliedWithLabelName"
              titleNumberOrLetter="(a)"
              title="Have the terms of the Order been complied with?"
              v-model="preservationOrderCompliedWith"
              validationFieldName="preservation order complied with"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="preservationOrderRelevantDocumentsLabelName"
              titleNumberOrLetter="(b)"
              title="Please supply a copy of any relevant documents."
              v-model="preservationOrderRelevantDocuments"
              validationFieldName="preservation order relevant documents"
            ></simple-question>
            <inline-file-uploader
              v-if="preservationOrderRelevantDocuments.answer === 'ETF_ENCLOSED'"
              :requiredDocument="alterations.preservationOrderRelevantDocuments"
            />
          </div>
        </div>
        <!-- /question 8 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
