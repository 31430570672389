<script>
import { mapMutations, mapState } from 'vuex';
import * as environmentalMatterMutations from '@/store/environmentalMatter/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import OpenQuestionWithAdditionals from '@/components/forms/inputGroups/OpenQuestionWithAdditionals';
import patchEnvironmentalMatter from '@/api/buildings/patchEnvironmentalMatters';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import environmentalMatters from '@/functions/supportingDocuments/environmentalMatters';

const yesNoEnclosedToFollow = {
  YNETF_YES: 'Yes',
  YNETF_NO: 'No',
  YNETF_ENCLOSED: 'Attached',
  YNETF_TO_FOLLOW: 'To follow',
};

const enclosedToFollowSupplied = {
  ETFS_ENCLOSED: 'Attached',
  ETFS_TO_FOLLOW: 'To follow',
  ETFS_ALREADY_SUPPLIED: 'Already Supplied',
};

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnown = {
  YNNK_YES: 'Yes',
  YNNK_NO: 'No',
  YNNK_NOT_KNOWN: 'Not Known',
};

export default {
  name: 'EnvironmentalMatter',
  components: { SimpleQuestion, OpenQuestionWithAdditionals, OpenQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_flooding: (state) => state.building.environmentalMatters.flooding,
      s_floodingDescription: (state) => state.building.environmentalMatters.floodingDescription,
      s_groundWater: (state) => state.building.environmentalMatters.groundWater,
      s_sewerFlooding: (state) => state.building.environmentalMatters.sewerFlooding,
      s_surfaceWater: (state) => state.building.environmentalMatters.surfaceWater,
      s_coastalFlooding: (state) => state.building.environmentalMatters.coastalFlooding,
      s_riverFlooding: (state) => state.building.environmentalMatters.riverFlooding,
      s_other: (state) => state.building.environmentalMatters.other,
      s_otherDescription: (state) => state.building.environmentalMatters.otherDescription,
      s_floodRiskReport: (state) => state.building.environmentalMatters.floodRiskReport,
      s_floodRiskReportCopy: (state) => state.building.environmentalMatters.floodRiskReportCopy,
      s_radonTest: (state) => state.building.environmentalMatters.radonTest,
      s_radonTestReport: (state) => state.building.environmentalMatters.radonTestReport,
      s_radonTestBelowRecommendedActionLevel: (state) =>
        state.building.environmentalMatters.radonTestBelowRecommendedActionLevel,
      s_radonTestRemedialMeasures: (state) => state.building.environmentalMatters.radonTestRemedialMeasures,
      s_epcCopy: (state) => state.building.environmentalMatters.epcCopy,
      s_installationsGreenDealFinanced: (state) => state.building.environmentalMatters.installationsGreenDealFinanced,
      s_installationsGreenDealFinancedElectricityBill: (state) =>
        state.building.environmentalMatters.installationsGreenDealFinancedElectricityBill,
      s_installationsGreenDealFinancedDescription: (state) =>
        state.building.environmentalMatters.installationsGreenDealFinancedDescription,
      s_propertyAffectedByJapaneseKnotweed: (state) =>
        state.building.environmentalMatters.propertyAffectedByJapaneseKnotweed,
      s_japaneseKnotweedManagementPlan: (state) => state.building.environmentalMatters.japaneseKnotweedManagementPlan,
      s_japaneseKnotweedManagementPlanCopy: (state) =>
        state.building.environmentalMatters.japaneseKnotweedManagementPlanCopy,
      s_buildingId: (state) => state.buildingId,
    }),
    flooding: {
      get() {
        return {
          answer: this.s_flooding,
          description: this.s_floodingDescription,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOODING}`
        ](value.answer);
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOODING_DESCRIPTION}`
        ](value.description);
      },
    },
    groundWater: {
      get() {
        return {
          answer: this.s_groundWater,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_GROUND_WATER}`
        ](value.answer);
      },
    },
    sewerFlooding: {
      get() {
        return {
          answer: this.s_sewerFlooding,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_SEWER_FLOODING}`
        ](value.answer);
      },
    },
    surfaceWater: {
      get() {
        return {
          answer: this.s_surfaceWater,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_SURFACE_WATER}`
        ](value.answer);
      },
    },
    coastalFlooding: {
      get() {
        return {
          answer: this.s_coastalFlooding,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_COASTAL_FLOODING}`
        ](value.answer);
      },
    },
    riverFlooding: {
      get() {
        return {
          answer: this.s_riverFlooding,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RIVER_FLOODING}`
        ](value.answer);
      },
    },
    other: {
      get() {
        return {
          answer: this.s_other,
          description: this.s_otherDescription,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_OTHER}`
        ](value.answer);
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_OTHER_DESCRIPTION}`
        ](value.description);
      },
    },
    floodRiskReport: {
      get() {
        return {
          answer: this.s_floodRiskReport,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT}`
        ](value.answer);
      },
    },
    floodRiskReportCopy: {
      get() {
        return {
          answer: this.s_floodRiskReportCopy,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT_COPY}`
        ](value.answer);
      },
    },
    radonTest: {
      get() {
        return {
          answer: this.s_radonTest,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST}`
        ](value.answer);
      },
    },
    radonTestReport: {
      get() {
        return {
          answer: this.s_radonTestReport,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REPORT}`
        ](value.answer);
      },
    },
    radonTestBelowRecommendedActionLevel: {
      get() {
        return {
          answer: this.s_radonTestBelowRecommendedActionLevel,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_BELOW_RECOMMENDED_ACTION_LEVEL}`
        ](value.answer);
      },
    },
    radonTestRemedialMeasures: {
      get() {
        return {
          answer: this.s_radonTestRemedialMeasures,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REMEDIAL_MEASURES}`
        ](value.answer);
      },
    },
    epcCopy: {
      get() {
        return {
          answer: this.s_epcCopy,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_EPC_COPY}`
        ](value.answer);
      },
    },
    installationsGreenDealFinanced: {
      get() {
        return {
          answer: this.s_installationsGreenDealFinanced,
          additionalAnswer: this.s_installationsGreenDealFinancedElectricityBill,
          description: this.s_installationsGreenDealFinancedDescription,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED}`
        ](value.answer);
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_ELECTRICITY_BILL}`
        ](value.additionalAnswer);
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_DESCRIPTION}`
        ](value.description);
      },
    },
    propertyAffectedByJapaneseKnotweed: {
      get() {
        return {
          answer: this.s_propertyAffectedByJapaneseKnotweed,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_PROPERTY_AFFECTED_BY_JAPANESE_KNOTWEED}`
        ](value.answer);
      },
    },
    japaneseKnotweedManagementPlan: {
      get() {
        return {
          answer: this.s_japaneseKnotweedManagementPlan,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN}`
        ](value.answer);
      },
    },
    japaneseKnotweedManagementPlanCopy: {
      get() {
        return {
          answer: this.s_japaneseKnotweedManagementPlanCopy,
        };
      },
      set(value) {
        this[
          `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY}`
        ](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      enclosedOrToFollow, // This refers to the content of the top on the file
      yesOrNo,
      yesOrNoOrNotKnown,
      yesNoEnclosedToFollow,
      enclosedToFollowSupplied,
      environmentalMatters,
    };
  },
  methods: {
    ...mapMutations([
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOODING}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOODING_DESCRIPTION}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_GROUND_WATER}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_SEWER_FLOODING}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_SURFACE_WATER}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_COASTAL_FLOODING}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RIVER_FLOODING}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_OTHER}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_OTHER_DESCRIPTION}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT_COPY}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REPORT}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_BELOW_RECOMMENDED_ACTION_LEVEL}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REMEDIAL_MEASURES}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_EPC_COPY}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_ELECTRICITY_BILL}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_DESCRIPTION}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_PROPERTY_AFFECTED_BY_JAPANESE_KNOTWEED}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN}`,
      `${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_PREFIX}${environmentalMatterMutations.ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchEnvironmentalMatter(
        this.s_buildingId,
        this.s_flooding,
        this.s_floodingDescription,
        this.s_groundWater,
        this.s_sewerFlooding,
        this.s_surfaceWater,
        this.s_coastalFlooding,
        this.s_riverFlooding,
        this.s_other,
        this.s_otherDescription,
        this.s_floodRiskReport,
        this.s_floodRiskReportCopy,
        this.s_radonTest,
        this.s_radonTestReport,
        this.s_radonTestBelowRecommendedActionLevel,
        this.s_radonTestRemedialMeasures,
        this.s_epcCopy,
        this.s_installationsGreenDealFinanced,
        this.s_installationsGreenDealFinancedElectricityBill,
        this.s_installationsGreenDealFinancedDescription,
        this.s_propertyAffectedByJapaneseKnotweed,
        this.s_japaneseKnotweedManagementPlan,
        this.s_japaneseKnotweedManagementPlanCopy,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Environmental matters</h2>

      <div class="col-md-12">
        <div class="section-title">
          <h3>Flooding</h3>
        </div>
      </div>

      <div class="col-md-12">
        <div class="section-note">
          <p>
            <b>Note:</b> Flooding may take a variety of forms: it may be seasonal or irregular or simply a one-off
            occurrence. The property does not need to be near a sea or river for flooding to occur. Further information
            about flooding can be found at:
            <a
              href="http://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs"
              target="_blank"
              >http://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs</a
            >. The flood risk check can be found at:
            <a href="http://www.gov.uk/check-flood-risk" target="_blank">www.gov.uk/check-flood-risk.</a>
          </p>
          <p>
            Read our updated Flood Risk Practice Note at
            <a href="https://www.lawsociety.org.uk/support-services/advice/practice-notes/flood-risk/" target="_blank"
              >https://www.lawsociety.org.uk/support-services/advice/practice-notes/flood-risk/</a
            >
          </p>
        </div>
      </div>

      <div class="border-top my-3"></div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <open-question
            v-model="flooding"
            title="Has any part of the property (whether buildings or surrounding garden or land) ever been
                        flooded?"
            descriptionTitle="If Yes, please state when the flooding occurred and identify the parts that flooded:"
            validationFieldName="flooding"
            labelName="floodingLabelName"
            dropdownName="floodingDropdownName"
            textAreaName="floodingTextAreaName"
            detailsName="flooding details"
          ></open-question>
        </div>
        <!-- /question 1 end -->

        <!-- If No answered for question above, skip question 2 -->
        <div v-if="flooding.answer" class="mt-2 border-top my-3">
          <!-- Question 2 -->
          <div class="mt-2 col-md-12">
            <h5>What type of flooding occurred?</h5>
          </div>
          <div class="col-md-12">
            <simple-question
              v-model="groundWater"
              :dropdownOptions="yesOrNo"
              labelName="groundWaterLabelName"
              titleNumberOrLetter="(a)"
              title="Ground water"
              validationFieldName="ground water"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="sewerFlooding"
              :dropdownOptions="yesOrNo"
              labelName="sewerFloodingLabelName"
              titleNumberOrLetter="(b)"
              title="Sewer flooding"
              validationFieldName="sewer flooding"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="surfaceWater"
              :dropdownOptions="yesOrNo"
              labelName="surfaceWaterLabelName"
              titleNumberOrLetter="(c)"
              title="Surface Water"
              validationFieldName="surface water"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="coastalFlooding"
              :dropdownOptions="yesOrNo"
              labelName="coastalFloodingLabelName"
              titleNumberOrLetter="(d)"
              title="Coastal Flooding"
              validationFieldName="coastal flooding"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="riverFlooding"
              :dropdownOptions="yesOrNo"
              labelName="riverFloodingLabelName"
              titleNumberOrLetter="(e)"
              title="River Flooding"
              validationFieldName="river flooding"
            ></simple-question>
          </div>

          <div class="col-md-12">
            <open-question
              v-model="other"
              titleNumberOrLetter="(f)"
              title="Other (please state):"
              validationFieldName="other"
              labelName="otherLabelName"
              dropdownName="otherDropdownName"
              textAreaName="otherTextAreaName"
              detailsName="other details"
            ></open-question>
          </div>
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <simple-question
            v-model="floodRiskReport"
            :dropdownOptions="yesOrNo"
            labelName="floodRiskReportLabelName"
            title="Has a Flood Risk Report been prepared?"
            descriptionTitle="If Yes, please supply a copy."
            validationFieldName="flooding risk"
          ></simple-question>
        </div>

        <div v-if="floodRiskReport.answer">
          <div class="col-md-12">
            <simple-question
              v-model="floodRiskReportCopy"
              :dropdownOptions="enclosedOrToFollow"
              labelName="floodRiskReportCopyLabelName"
              validationFieldName="flood risk report copy"
              title=""
            ></simple-question>
            <inline-file-uploader
              v-if="floodRiskReportCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="environmentalMatters.floodRiskReportCopy"
            />
          </div>
        </div>

        <div class="mt-2 col-md-12">
          <div class="section-note">
            <p>
              Further information about the types of flooding and Flood Risk Reports can be found at:
              <a href="http://www.environment-agency.gov.uk" target="_blank">www.environment-agency.gov.uk</a>.
            </p>
          </div>
        </div>
        <!-- /question 3 end -->

        <!-- Radeon Info -->
        <div class="col-md-12">
          <div class="section-title">
            <h3>Radon</h3>
          </div>
        </div>

        <div class="col-md-12">
          <div class="section-note">
            <p>
              <b>Note:</b> Radon is a naturally occurring inert radioactive gas found in the ground. Some parts of
              England and Wales are more adversely affected by it than others. Remedial action is advised for properties
              with a test result above the ‘recommended action level’. Further information about Radon can be found at:
              <a href="http://www.gov.uk/government/organisations/public-health-england" target="_blank"
                >www.gov.uk/government/organisations/public-health-england</a
              >
              and
              <a href="http://www.publichealthwales.wales.nhs.uk" target="_blank">www.publichealthwales.wales.nhs.uk.</a
              >.
            </p>
          </div>
        </div>

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <simple-question
            v-model="radonTest"
            :dropdownOptions="yesOrNo"
            labelName="radonTestLabelName"
            title="Has a Radon test been carried out on the property?"
            validationFieldName="radon test flooding"
          ></simple-question>
        </div>

        <div v-if="radonTest.answer">
          <div class="col-md-12">
            <simple-question
              v-model="radonTestReport"
              :dropdownOptions="enclosedOrToFollow"
              labelName="radonTestReportLabelName"
              titleNumberOrLetter="(a)"
              title="please supply a copy of the report"
              validationFieldName="radon test"
            ></simple-question>
            <inline-file-uploader
              v-if="radonTestReport.answer === 'ETF_ENCLOSED'"
              :requiredDocument="environmentalMatters.radonTestReport"
            />
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="radonTestBelowRecommendedActionLevel"
              :dropdownOptions="yesOrNo"
              labelName="radonTestBelowRecommendedActionLevelLabelName"
              titleNumberOrLetter="(b)"
              title="was the test result below the ‘recommended action level’?"
              validationFieldName="radon below"
            ></simple-question>
          </div>
        </div>
        <!-- /question 4 end -->

        <div class="border-top my-3"></div>

        <!-- Question 5 -->
        <div class="col-md-12">
          <simple-question
            v-model="radonTestRemedialMeasures"
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="radonTestRemedialMeasuresLabelName"
            title="Were any remedial measures undertaken on construction to reduce Radon gas levels in the property?"
            validationFieldName="radon remedial measures"
          ></simple-question>
        </div>
        <!-- /question 5 end -->

        <!-- Energy efficiency info -->
        <div class="col-md-12">
          <div class="section-title">
            <h3>Energy efficiency</h3>
          </div>
        </div>

        <!-- /energy efficiency info -->

        <!-- Question 6 -->
        <div class="col-md-12">
          <simple-question
            v-model="epcCopy"
            :dropdownOptions="enclosedToFollowSupplied"
            labelName="epcCopyLabelName"
            title="Please supply a copy of the EPC for the property."
            validationFieldName="epc copy"
          ></simple-question>
          <inline-file-uploader
            v-if="epcCopy.answer === 'ETFS_ENCLOSED'"
            :requiredDocument="environmentalMatters.epcCopy"
          />
        </div>
        <!-- /question 6 end -->

        <div class="border-top my-3"></div>

        <!-- Question 7 -->
        <div class="col-md-12">
          <open-question-with-additionals
            v-model="installationsGreenDealFinanced"
            title="Have any installations in the property been financed under the
                                Green Deal scheme? If Yes, please give details of all
                                installations and supply a copy of your last electricity bill."
            validationFieldName="green deal scheme"
            labelName="installationsGreenDealFinancedLabelName"
            dropdownName="installationsGreenDealFinancedDropdownName"
            textAreaName="installationsGreenDealFinancedTextAreaName"
            additionalDropdownName="installationsGreenDealFinancedAdditionalDropdownName"
            :additional-answers="enclosedOrToFollow"
          >
            <inline-file-uploader
              v-if="installationsGreenDealFinanced.additionalAnswer === 'ETF_ENCLOSED'"
              :requiredDocument="environmentalMatters.installationsGreenDealFinanced"
            />
          </open-question-with-additionals>

          <div class="section-note">
            <p>
              Further information about the Green Deal can be found at:
              <a href="http://www.gov.uk/green-deal-energy-saving-measures" target="_blank"
                >www.gov.uk/green-deal-energy-saving-measures</a
              >
            </p>
          </div>
        </div>
        <!-- /question 7 end -->

        <!-- Question 8 -->
        <div class="col-md-12">
          <div class="section-title">
            <h3>Japanese knotweed</h3>
          </div>
        </div>

        <div class="col-md-12">
          <div class="section-note">
            <p>
              <b>Note:</b> Japanese knotweed is an invasive non-native plant that can cause damage to property if left
              untreated. The plant consists of visible above ground growth and an invisible rhizome (root) below ground
              in the soil. It can take several years to control and manage through a management and treatment plan and
              rhizomes may remain alive below the soil even after treatment.
            </p>
          </div>
        </div>

        <div class="border-top my-3"></div>

        <div class="col-md-12">
          <simple-question
            v-model="propertyAffectedByJapaneseKnotweed"
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="propertyAffectedByJapaneseKnotweedLabelName"
            title="Is the property affected by Japanese knotweed?"
            validationFieldName="property affected by japanese knotweed"
          ></simple-question>
        </div>

        <div class="col-md-12" v-if="propertyAffectedByJapaneseKnotweed.answer === 'YNNK_YES'">
          <h5>If Yes,</h5>

          <simple-question
            v-model="japaneseKnotweedManagementPlan"
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="japaneseKnotweedManagementPlanLabelName"
            title="please state whether there is a Japanese knotweed management plan in place"
            validationFieldName="japanese knotweed management plan"
          ></simple-question>

          <simple-question
            v-model="japaneseKnotweedManagementPlanCopy"
            :dropdownOptions="enclosedOrToFollow"
            labelName="japaneseKnotweedManagementPlanCopyLabelName"
            title="please supply a copy with any insurance cover linked to the plan."
            validationFieldName="japanese knotweed management plan copy"
          ></simple-question>
          <inline-file-uploader
            v-if="japaneseKnotweedManagementPlanCopy.answer === 'ETF_ENCLOSED'"
            :requiredDocument="environmentalMatters.japaneseKnotweedManagementPlanCopy"
          />
        </div>
        <!-- /question 8 end-->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
