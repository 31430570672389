import axios from 'axios';

export default (id, propertyRelatedCharges, propertyRelatedChargesDetails) => {
  const preparedData = {};

  preparedData.property_related_charges = propertyRelatedCharges;
  preparedData.property_related_charges_details = propertyRelatedCharges ? propertyRelatedChargesDetails : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    other_charges: preparedData,
  });
};
