<script>
import { mapMutations, mapState } from 'vuex';
import * as transactionInformationMutations from '@/store/transactionInformation/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchTransactionInformation from '@/api/buildings/patchTransactionInformation';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoNoMortgages = {
  YNNM_YES: 'Yes',
  YNNM_NO: 'No',
  YNNM_NO_MORTGAGE: 'No Mortgage',
};

export default {
  components: { OpenQuestion, SimpleQuestion },
  name: 'TransactionInformation',
  computed: {
    ...mapState({
      s_buildingId: (state) => state.buildingId,
      s_sameDayCompletion: (state) => state.building.transactionInformation.sameDayCompletion,
      s_sellerMovingDateRequirements: (state) => state.building.transactionInformation.sellerMovingDateRequirements,
      s_sellerMovingDateRequirementsDescription: (state) =>
        state.building.transactionInformation.sellerMovingDateRequirementsDescription,
      s_salePriceExceedsMortgageCharges: (state) =>
        state.building.transactionInformation.salePriceExceedsMortgageCharges,
      s_buildingTidy: (state) => state.building.transactionInformation.buildingTidy,
      s_emptyLightFittingsReplaced: (state) => state.building.transactionInformation.emptyLightFittingsReplaced,
      s_fittingRemovalCare: (state) => state.building.transactionInformation.fittingRemovalCare,
      s_securityDetailsProvidedAtBuilding: (state) =>
        state.building.transactionInformation.securityDetailsProvidedAtBuilding,
    }),
    sameDayCompletion: {
      get() {
        return {
          answer: this.s_sameDayCompletion,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SAME_DAY_COMPLETION}`
        ](value.answer);
      },
    },
    sellerMovingDateRequirements: {
      get() {
        return {
          answer: this.s_sellerMovingDateRequirements,
          description: this.s_sellerMovingDateRequirementsDescription,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS}`
        ](value.answer);
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS_DESCRIPTION}`
        ](value.description);
      },
    },
    salePriceExceedsMortgageCharges: {
      get() {
        return {
          answer: this.s_salePriceExceedsMortgageCharges,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SALE_PRICE_EXCEEDS_MORTGAGE_CHARGES}`
        ](value.answer);
      },
    },
    buildingTidy: {
      get() {
        return {
          answer: this.s_buildingTidy,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_BUILDING_TIDY}`
        ](value.answer);
      },
    },
    emptyLightFittingsReplaced: {
      get() {
        return {
          answer: this.s_emptyLightFittingsReplaced,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_EMPTY_LIGHT_FITTINGS_REPLACED}`
        ](value.answer);
      },
    },
    fittingRemovalCare: {
      get() {
        return {
          answer: this.s_fittingRemovalCare,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_FITTING_REMOVAL_CARE}`
        ](value.answer);
      },
    },
    securityDetailsProvidedAtBuilding: {
      get() {
        return {
          answer: this.s_securityDetailsProvidedAtBuilding,
        };
      },
      set(value) {
        this[
          `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SECURITY_DETAILS_PROVIDED_AT_BUILDING}`
        ](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      yesOrNoNoMortgages,
    };
  },
  methods: {
    ...mapMutations([
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SAME_DAY_COMPLETION}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS_DESCRIPTION}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SALE_PRICE_EXCEEDS_MORTGAGE_CHARGES}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_BUILDING_TIDY}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_EMPTY_LIGHT_FITTINGS_REPLACED}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_FITTING_REMOVAL_CARE}`,
      `${transactionInformationMutations.TRANSACTION_INFORMATION_PREFIX}${transactionInformationMutations.TRANSACTION_INFORMATION_SET_SECURITY_DETAILS_PROVIDED_AT_BUILDING}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchTransactionInformation(
        this.s_buildingId,
        this.s_sameDayCompletion,
        this.s_sellerMovingDateRequirements,
        this.s_sellerMovingDateRequirementsDescription,
        this.s_salePriceExceedsMortgageCharges,
        this.s_buildingTidy,
        this.s_emptyLightFittingsReplaced,
        this.s_fittingRemovalCare,
        this.s_securityDetailsProvidedAtBuilding,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error))
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Transaction information</h2>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="sameDayCompletionLabelName"
            titleNumberOrLetter=""
            title="Is this sale dependent on the seller completing the purchase of another property on the same day?"
            v-model="sameDayCompletion"
            validationFieldName="same day completion"
          />
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <open-question
            :dropdownOptions="yesOrNo"
            detailsName="seller moving date requirements details"
            descriptionTitle="If Yes, please give details:"
            dropdownName="sellerMovingDateRequirementsDropdownName"
            labelName="sellerMovingDateRequirementsLabelName"
            textAreaName="sellerMovingDateRequirementsTextAreaName"
            title="Does the seller have any special requirements about a moving date?"
            v-model="sellerMovingDateRequirements"
            validationFieldName="seller moving date requirements"
          />
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoNoMortgages"
            labelName="salePriceExceedsMortgageChargesLabelName"
            titleNumberOrLetter=""
            title="Will the sale price be sufficient to repay all mortgages and charges secured on the property?"
            v-model="salePriceExceedsMortgageCharges"
            validationFieldName="sale price exceeds mortgage charges"
          />
        </div>
        <!-- /question 3 end -->

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <h5><label>Will the seller ensure that: </label></h5>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="buildingTidyLabelName"
            titleNumberOrLetter="(a)"
            title="all rubbish is removed from the property (including from the loft, garden, outbuildings, garages and sheds) and that the property will be left in a clean and tidy condition?"
            v-model="buildingTidy"
            validationFieldName="building tidy"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="emptyLightFittingsReplacedLabelName"
            titleNumberOrLetter="(b)"
            title="if light fittings are removed, the fittings will be replaced with ceiling rose, flex, bulb holder and bulb?"
            v-model="emptyLightFittingsReplaced"
            validationFieldName="empty light fittings replaced"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="fittingRemovalCareLabelName"
            titleNumberOrLetter="(c)"
            title="reasonable care will be taken when removing any other fittings or contents?"
            v-model="fittingRemovalCare"
            validationFieldName="fitting removal care"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="securityDetailsProvidedAtBuildingLabelName"
            titleNumberOrLetter="(d)"
            title="keys to all windows and doors and details of alarm codes will be left at the property or with the estate agent?"
            v-model="securityDetailsProvidedAtBuilding"
            validationFieldName="security details provided at building"
          />
        </div>
        <!-- /question 4 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
