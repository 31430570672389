<script>
import { mapMutations, mapState } from 'vuex';
import * as guaranteesMutations from '@/store/guarantees/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import patchGuarantees from '@/api/buildings/patchGuarantees';
import OpenQuestionWithAdditionals from '@/components/forms/inputGroups/OpenQuestionWithAdditionals';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import guarantees from '@/functions/supportingDocuments/guarantees';

/**
 * Options for the guarantee service.
 */
const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

/**
 * Options for enclosed or follow.
 *
 * @type {{enclosed: string, "to follow": string}}
 */
const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

export default {
  name: 'GuaranteesAndWarranties',
  components: { SimpleQuestion, OpenQuestion, OpenQuestionWithAdditionals, InlineFileUploader },
  computed: {
    ...mapState({
      s_newHomeWarranty: (state) => state.building.guarantees.newHomeWarranty,
      s_newHomeWarrantyCopy: (state) => state.building.guarantees.newHomeWarrantyCopy,
      s_dampProofing: (state) => state.building.guarantees.dampProofing,
      s_dampProofingCopy: (state) => state.building.guarantees.dampProofingCopy,
      s_timberTreatment: (state) => state.building.guarantees.timberTreatment,
      s_timberTreatmentCopy: (state) => state.building.guarantees.timberTreatmentCopy,
      s_windows: (state) => state.building.guarantees.windows,
      s_windowsCopy: (state) => state.building.guarantees.windowsCopy,
      s_electricalWork: (state) => state.building.guarantees.electricalWork,
      s_electricalWorkCopy: (state) => state.building.guarantees.electricalWorkCopy,
      s_roofing: (state) => state.building.guarantees.roofing,
      s_roofingCopy: (state) => state.building.guarantees.roofingCopy,
      s_centralHeating: (state) => state.building.guarantees.centralHeating,
      s_centralHeatingCopy: (state) => state.building.guarantees.centralHeatingCopy,
      s_underpinning: (state) => state.building.guarantees.underpinning,
      s_underpinningCopy: (state) => state.building.guarantees.underpinningCopy,
      s_other: (state) => state.building.guarantees.other,
      s_otherCopy: (state) => state.building.guarantees.otherCopy,
      s_otherDescription: (state) => state.building.guarantees.otherDescription,
      s_claims: (state) => state.building.guarantees.claims,
      s_claimsDescription: (state) => state.building.guarantees.claimsDescription,
      s_buildingId: (state) => state.buildingId,
    }),
    newHomeWarranty: {
      get() {
        return {
          answer: this.s_newHomeWarranty,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_NEW_HOME_WARRANTY}`](
          value.answer,
        );
      },
    },
    newHomeWarrantyCopy: {
      get() {
        return {
          answer: this.s_newHomeWarrantyCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_NEW_HOME_WARRANTY_COPY}`](
          value.answer,
        );
      },
    },
    dampProofing: {
      get() {
        return {
          answer: this.s_dampProofing,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_DAMP_PROOFING}`](
          value.answer,
        );
      },
    },
    dampProofingCopy: {
      get() {
        return {
          answer: this.s_dampProofingCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_DAMP_PROOFING_COPY}`](
          value.answer,
        );
      },
    },
    timberTreatment: {
      get() {
        return {
          answer: this.s_timberTreatment,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_TIMBER_TREATMENT}`](
          value.answer,
        );
      },
    },
    timberTreatmentCopy: {
      get() {
        return {
          answer: this.s_timberTreatmentCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_TIMBER_TREATMENT_COPY}`](
          value.answer,
        );
      },
    },
    windows: {
      get() {
        return {
          answer: this.s_windows,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_WINDOWS}`](value.answer);
      },
    },
    windowsCopy: {
      get() {
        return {
          answer: this.s_windowsCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_WINDOWS_COPY}`](
          value.answer,
        );
      },
    },
    electricalWork: {
      get() {
        return {
          answer: this.s_electricalWork,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ELECTRICAL_WORK}`](
          value.answer,
        );
      },
    },
    electricalWorkCopy: {
      get() {
        return {
          answer: this.s_electricalWorkCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ELECTRICAL_WORK_COPY}`](
          value.answer,
        );
      },
    },
    roofing: {
      get() {
        return {
          answer: this.s_roofing,
          InlineFileUploader,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ROOFING}`](value.answer);
      },
    },
    roofingCopy: {
      get() {
        return {
          answer: this.s_roofingCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ROOFING_COPY}`](
          value.answer,
        );
      },
    },
    centralHeating: {
      get() {
        return {
          answer: this.s_centralHeating,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CENTRAL_HEATING}`](
          value.answer,
        );
      },
    },
    centralHeatingCopy: {
      get() {
        return {
          answer: this.s_centralHeatingCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CENTRAL_HEATING_COPY}`](
          value.answer,
        );
      },
    },
    underpinning: {
      get() {
        return {
          answer: this.s_underpinning,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_UNDER_PINNING}`](
          value.answer,
        );
      },
    },
    underpinningCopy: {
      get() {
        return {
          answer: this.s_underpinningCopy,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_UNDER_PINNING_COPY}`](
          value.answer,
        );
      },
    },
    other: {
      get() {
        return {
          answer: this.s_other,
          additionalAnswer: this.s_otherCopy,
          description: this.s_otherDescription,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_OTHER}`](value.answer);
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_OTHER_COPY}`](
          value.additionalAnswer,
        );
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_OTHER_DESCRIPTION}`](
          value.description,
        );
      },
    },
    claims: {
      get() {
        return {
          answer: this.s_claims,
          description: this.s_claimsDescription,
        };
      },
      set(value) {
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CLAIMS}`](value.answer);
        this[`${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CLAIMS_DESCRIPTION}`](
          value.description,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      enclosedOrToFollow,
      guarantees,
    };
  },
  methods: {
    ...mapMutations([
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_NEW_HOME_WARRANTY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_NEW_HOME_WARRANTY_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_DAMP_PROOFING}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_DAMP_PROOFING_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_TIMBER_TREATMENT}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_TIMBER_TREATMENT_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_WINDOWS}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_WINDOWS_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ELECTRICAL_WORK}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ELECTRICAL_WORK_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ROOFING}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_ROOFING_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CENTRAL_HEATING}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CENTRAL_HEATING_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_UNDER_PINNING}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_UNDER_PINNING_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_OTHER}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_OTHER_COPY}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_OTHER_DESCRIPTION}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CLAIMS}`,
      `${guaranteesMutations.GUARANTEES_PREFIX}${guaranteesMutations.GUARANTEES_SET_CLAIMS_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchGuarantees(
        this.s_buildingId,
        this.s_newHomeWarranty,
        this.s_newHomeWarrantyCopy,
        this.s_dampProofing,
        this.s_dampProofingCopy,
        this.s_timberTreatment,
        this.s_timberTreatmentCopy,
        this.s_windows,
        this.s_windowsCopy,
        this.s_electricalWork,
        this.s_electricalWorkCopy,
        this.s_roofing,
        this.s_roofingCopy,
        this.s_centralHeating,
        this.s_centralHeatingCopy,
        this.s_underpinning,
        this.s_underpinningCopy,
        this.s_other,
        this.s_otherCopy,
        this.s_otherDescription,
        this.s_claims,
        this.s_claimsDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Guarantees and warranties</h2>

      <div class="col-md-12">
        <div class="section-note">
          <p>
            <b>Note to seller:</b> All available guarantees, warranties and supporting paperwork should be supplied
            before exchange of contracts.
          </p>
        </div>
      </div>

      <div class="col-md-12 my-3">
        <div class="section-note">
          <p>
            <b>Note to buyer:</b> Some guarantees only operate to protect the person who had the work carried out or may
            not be valid if their terms have been breached. You may wish to contact the company to establish whether it
            is still trading and if so, whether the terms of the guarantee will apply to you.
          </p>
        </div>
      </div>

      <div class="border-top my-3"></div>

      <div class="col-md-12">
        <h5>
          <label
            >Does the property benefit from any of the following guarantees or warranties? If Yes, please supply a copy.
          </label>
        </h5>
      </div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="newHomeWarrantyLabelName"
            titleNumberOrLetter="(a)"
            title="New home warranty (e.g. NHBC or similar)"
            v-model="newHomeWarranty"
            validationFieldName="new home warranty"
          />

          <div v-if="this.s_newHomeWarranty">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="newHomeWarrantyCopyLabelName"
              title="Please supply a copy."
              v-model="newHomeWarrantyCopy"
              validationFieldName="new home warranty copy"
            />
            <inline-file-uploader
              v-if="newHomeWarrantyCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.newHomeWarrantyCopy"
            />
          </div>
        </div>
        <!-- /question 1 end -->

        <!-- Question 2 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="dampProofingLabelName"
            titleNumberOrLetter="(b)"
            title="Damp proofing"
            v-model="dampProofing"
            validationFieldName="damp proofing"
          />

          <div v-if="this.s_dampProofing">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="dampProofingCopyLabelName"
              title="Please supply a copy."
              v-model="dampProofingCopy"
              validationFieldName="damp proofing copy"
            />
            <inline-file-uploader
              v-if="dampProofingCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.dampProofingCopy"
            />
          </div>
        </div>
        <!-- /question 2 end -->

        <!-- Question 3 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="timberTreatmentLabelName"
            titleNumberOrLetter="(c)"
            title="Timber treatment"
            v-model="timberTreatment"
            validationFieldName="timber treatment"
          />

          <div v-if="this.s_timberTreatment">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="timberTreatmentCopyLabelName"
              title="Please supply a copy."
              v-model="timberTreatmentCopy"
              validationFieldName="timber treatment copy"
            />
            <inline-file-uploader
              v-if="timberTreatmentCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.timberTreatmentCopy"
            />
          </div>
        </div>
        <!-- /question 3 end -->

        <!-- Question 4 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="windowsLabelName"
            titleNumberOrLetter="(d)"
            title="Windows, rood lights, roof windows or glazed doors"
            v-model="windows"
            validationFieldName="windows"
          />

          <div v-if="this.s_windows">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="windowsCopyLabelName"
              title="Please supply a copy."
              v-model="windowsCopy"
              validationFieldName="windows copy"
            />
            <inline-file-uploader
              v-if="windowsCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.windowsCopy"
            />
          </div>
        </div>
        <!-- /question 4 end -->

        <!-- Question 5 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="electricalWorkLabelName"
            titleNumberOrLetter="(e)"
            title="Electrical work"
            v-model="electricalWork"
            validationFieldName="electrical work"
          />

          <div v-if="this.s_electricalWork">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="electricalWorkCopyLabelName"
              title="Please supply a copy."
              v-model="electricalWorkCopy"
              validationFieldName="electrical work copy"
            />
            <inline-file-uploader
              v-if="electricalWorkCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.electricalWorkCopy"
            />
          </div>
        </div>
        <!-- /question 5 end -->

        <!-- Question 6 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="roofingLabelName"
            titleNumberOrLetter="(f)"
            title="Roofing"
            v-model="roofing"
            validationFieldName="roofing"
          />

          <div v-if="this.s_roofing">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="roofingCopyLabelName"
              title="Please supply a copy."
              v-model="roofingCopy"
              validationFieldName="roofing copy"
            />
            <inline-file-uploader
              v-if="roofingCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.roofingCopy"
            />
          </div>
        </div>
        <!-- /question 6 end -->

        <!-- Question 7 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="centralHeatingLabelName"
            titleNumberOrLetter="(g)"
            title="Central heating"
            v-model="centralHeating"
            validationFieldName="central heating"
          />

          <div v-if="this.s_centralHeating">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="centralHeatingCopyLabelName"
              title="Please supply a copy."
              v-model="centralHeatingCopy"
              validationFieldName="central heating copy"
            />
            <inline-file-uploader
              v-if="centralHeatingCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.centralHeatingCopy"
            />
          </div>
        </div>
        <!-- /question 7 end -->

        <!-- Question 8 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="underpinningLabelName"
            titleNumberOrLetter="(h)"
            title="Underpinning"
            v-model="underpinning"
            validationFieldName="underpinning"
          />

          <div v-if="this.s_underpinning">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="underpinningCopyLabelName"
              title="Please supply a copy."
              v-model="underpinningCopy"
              validationFieldName="underpinning copy"
            />
            <inline-file-uploader
              v-if="underpinningCopy.answer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.underpinningCopy"
            />
          </div>
        </div>
        <!-- question 8 end -->

        <!-- Question 9 -->
        <div class="col-md-12">
          <open-question-with-additionals
            :additional-answers="enclosedOrToFollow"
            additionalDropdownName="otherAdditionalDropdownName"
            dropdownName="otherDropdownName"
            labelName="otherLabelName"
            textAreaName="otherTextAreaName"
            title="Other (please state):"
            v-model="other"
            validationFieldName="other"
          >
            <inline-file-uploader
              v-if="other.additionalAnswer === 'ETF_ENCLOSED'"
              :requiredDocument="guarantees.other"
            />
          </open-question-with-additionals>
        </div>
        <!-- /question 9 end -->

        <!-- Question 10 -->
        <div class="col-md-12 border-top my-3">
          <open-question
            additionalDropdownName="claimsAdditionalDropdownName"
            dropdownName="claimsDropdownName"
            labelName="claimsLabelName"
            textAreaName="claimsTextAreaName"
            title="Have any claims been made under any of these guarantees or warranties?"
            descriptionTitle="If Yes, please give details:"
            v-model="claims"
            validationFieldName="claims"
          />
        </div>
        <!-- /question 10 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
